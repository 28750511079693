import React, { useRef, Fragment, useState, useEffect } from "react";


import avatar from "../../assets/images/avatar.png"

import attach from "../../assets/images/attach.png"
import user from "../../assets/images/user.png"
import lftarr from "../../assets/images/left-arrow.png"
import polyrgt from "../../assets/images/poly-right.svg"
import polyleft from "../../assets/images/poly-left.svg"
import emoji from "../../assets/images/emoji.png"

import { useNavigate } from "react-router";

import { postRequest, usePostRequest } from "../../Services/Queries";

import io from 'socket.io-client';
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

import EmojiPicker from "emoji-picker-react";

import { toast } from "react-toastify";


const Chat = (props) => {
    const chatMessagesRef = useRef();
    const navigate = useNavigate();

    let socketURL = Helper?.SocketBaseurl();


    const socket = io.connect(socketURL);



    const {
        loaderProperty: {
            setLoading
        }
    }
        = useCustomizeContext()

    const [isEmojiToggle, setIsEmojiToggle] = useState(false);

    const [chatMessages, setChatMessages] = useState([]);

    const [chatId, setChatId] = useState("");
    const [profileImg, setProfileImg] = useState("");
    const [sendMessage, setSendMessage] = useState("");
    const [page,setpage] = useState(1)
    const[duration,Setduration] = useState({})

    let { mutateAsync: getInitateChat } = usePostRequest(QueryKeys?.GET_INITATE_CHAT_KEY);
    let { mutateAsync: getUserChat } = usePostRequest(QueryKeys?.GET_USER_CHAT);


    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTo(0, chatMessagesRef.current.scrollHeight);
        }
    }, [chatMessages]);

    useEffect(() => {
        if (localStorage.getItem("chatID")) {
            let obj = {
                chatId: localStorage.getItem("chatID"),
                type: "User",
                message: "",
                attachment: "",
                chathistory: false
            }
            getChatMessages(obj);
        }
        else {
            getChatMessages({});
        }
    }, [getInitateChat]);


    const getChatMessages = async (payload) => {
        let postObj = {
            Api: USER_APIS?.GET_INITIATE_CHAT,
            Payload: payload,
            Type: ""
        }
        let res = await getInitateChat(postObj);
        if (res?.status === true) {
            setChatId(res?.chatId);
            localStorage.setItem("chatID", res?.chatId);
            localStorage.setItem("devicetype", res?.mobile);
            Setduration({startdate:res.duration ? res.duration : "",currentdate:res.currentday ? res.currentday : "", isChatExists:res?.isChatExists})
            setChatMessages(res?.data);
            
            setProfileImg(res?.userData?.userDetails?.profile_picture);
        }
    }

    const handleCopyText = (message) => {
     

        if (message.type === 'text') {
            Helper.copyFunction(message.message, "Text copied Successfully")
        }
    };


    const chatMessage = async () => {

        if (sendMessage.length > 0) {
            let payload = {
                userId: localStorage.getItem("chatToken"),
                chatId: chatId,
                type: "User",
                message: sendMessage,
                attachment: "",
                chathistory: false

            }

            if (localStorage.getItem("devicetype")) {
                setSendMessage("");

                let postObj = {
                    Api: USER_APIS?.GET_USER_CHAT_DETAILS,
                    Payload: payload,
                    Type: ""
                }
                let res = await getUserChat(postObj);
            
                if (res?.status === true) {

                    setChatMessages(res?.data);

                }

            }
            else {
                socket.emit("ylper_resu", payload)
            }
            setSendMessage("");
            setIsEmojiToggle(false);
        }

        else {
            return
        }
    }


    socket.on("ophueunekmek", async function (data) {
        console.log("🚀 ~ data: ophueunekmek", data)
        if (data?.status) {
            if (data?.uid === localStorage.getItem("uid")) {
                setChatMessages(data?.data?.messages);
                setProfileImg(data?.data?.userDetails?.profile_picture);
            }
        }
    })

    const getfullcharthistory = async () => {



        if (localStorage.getItem("chatID")) {
            let obj = {
                chatId: localStorage.getItem("chatID"),
                type: "User",
                message: "",
                attachment: "",
                chathistory: true,
                pagination:page,
                duration:duration.startdate,
                currentday:duration.currentdate
            }
            setpage(page+1)
            getChatMessages(obj);
        }


    }

    

    const getlatestcharthistory = async () => {


        if (localStorage.getItem("chatID")) {
            let obj = {
                chatId: localStorage.getItem("chatID"),
                type: "User",
                message: "",
                attachment: "",
                chathistory: false
            }
            getChatMessages(obj);
        }


    }

    const getImage = async (e) => {
        const format = ["image/jpg", "image/png", "image/jpeg"];

        const file = e.target.files[0];
        if (!format.includes(file.type)) {
            toast.error("JPG,PNG,JPEG format only allowed");
        }
        else {
            let isValidImage = await Helper?.isValidFile(e, file, 'img');
            if (isValidImage) {
                let obj = {
                    image: e.target.files[0]
                }
                uploadFile(obj);
            }
        }
    }

    const uploadFile = async (payload) => {

        let postObj = {
            Api: USER_APIS?.CHAT_ATTACHEMENT,
            Payload: payload,
            Type: "formdata"
        }

        setLoading(true);

        let res = await postRequest(postObj)
        if (res?.status === true) {
            let payload = {
                userId: localStorage.getItem("chatToken"),
                chatId: chatId,
                type: "User",
                message: sendMessage,
                attachment: res?.imageUrl
            }

            if (localStorage.getItem("devicetype")) {
                let postObj = {
                    Api: USER_APIS?.GET_USER_CHAT_DETAILS,
                    Payload: payload,
                    Type: ""
                }
                let res = await getUserChat(postObj);
                if (res?.status === true) {

                    setChatMessages(res?.data);
                }

            }
            else {
                socket.emit("ylper_resu", payload)
            }
            setLoading(false);
        }
        else
            setLoading(false);

    }

    const emojIsToggle = () => setIsEmojiToggle(!isEmojiToggle)

    const emojiPickerFunction = (emojiObject) => {
        const emoji = emojiObject.emoji;
        setSendMessage((pre) => pre + emoji)
    };

    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="EmailVrfyPg ChatPg">
                        <div className="container">
                            <div className="EmailVrfyTp ChatTp">
                                <img src={lftarr} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                <h4>Online Customer Service</h4>
                                <div></div>
                            </div>
                            <div className="ChatBg">
                                {duration?.isChatExists ? 
                                <button className="btn cntbtncmn" onClick={() => getfullcharthistory()}>Chat History</button>
                                :  <button className="btn cntbtncmn" onClick={() => getlatestcharthistory()}>Latest Chat</button>}
                             
                                <div className="ChatDts scroller mt-2" ref={chatMessagesRef}>
                                    {
                                        chatMessages?.map((temp, index) =>
                                            <div key={index}>
                                                {
                                                    temp?.sender === "Admin" ?
                                                        <div className="ChatDtsL">
                                                            <div className="d-flex align-items-center">
                                                                <img src={avatar} className="mr-3" width="30" height="30" />
                                                                <div style={{ cursor: "pointer" }} onClick={() => handleCopyText(temp)} className="ChatDtsCtBg mt-2">
                                                                    <span>{temp?.message}</span>
                                                                    {temp?.attachment === "" ? null : <img style={{ width: "250px", height: "250px" }} src={temp?.attachment} />}
                                                                    <img src={polyleft} className="polylft" />
                                                                </div>
                                                            </div>

                                                            <div className="ChatDtsCt">
                                                                <p>{Helper?.formatDated(temp?.messageTime)}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="ChatDtsR">
                                                            <div>
                                                                <div className="d-flex align-items-center justify-content-end">
                                                                    <div style={{ cursor: "pointer" }} onClick={() => handleCopyText(temp)} className="ChatDtsCtBg">
                                                                        <span>{temp?.message}</span>
                                                                        {temp?.attachment === "" ? null : <img style={{ width: "250px", height: "250px" }} src={temp?.attachment} />}
                                                                        <img src={polyrgt} className="polyrgt" />
                                                                    </div>
                                                                    <img src={profileImg ? profileImg : user} style={{ height: "30px", width: "30px" }} className="ml-3" />
                                                                </div>
                                                                <div className="ChatDtsCt mr-2">
                                                                    <p>{Helper?.formatDated(temp?.messageTime)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    isEmojiToggle ?
                                        <div className="chatCls">
                                            <EmojiPicker onEmojiClick={emojiPickerFunction} className="w-100" />
                                        </div>
                                        :
                                        null
                                }

                                <div className="ChatSndDv">


                                    <div className="ChatSndDvAth">
                                        <img src={attach} className="mr-2" style={{ cursor: "pointer" }} />
                                        <input type="file" id="chatImg" className="ChtIg" onChange={getImage} accept="image/*" style={{ opacity: '0' }} />
                                    </div>

                                    <input type="text"
                                        className="form-control"
                                        placeholder="Please enter..."
                                        value={sendMessage}
                                        onChange={(event) => setSendMessage(event?.target?.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter")
                                                chatMessage();
                                        }}
                                    />

                                    <img src={emoji} className="emojiIg" onClick={emojIsToggle} />

                                    <svg className="ml-2" style={{ cursor: "pointer" }} onClick={chatMessage} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="23" cy="23" r="23" fill={sendMessage.length > 0 ? "#1652f0" : "#C7CAD2"} />
                                        <path d="M23.7071 11.2929C23.3166 10.9024 22.6834 10.9024 22.2929 11.2929L15.9289 17.6569C15.5384 18.0474 15.5384 18.6805 15.9289 19.0711C16.3195 19.4616 16.9526 19.4616 17.3431 19.0711L23 13.4142L28.6569 19.0711C29.0474 19.4616 29.6805 19.4616 30.0711 19.0711C30.4616 18.6805 30.4616 18.0474 30.0711 17.6569L23.7071 11.2929ZM24 34L24 12L22 12L22 34L24 34Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </Fragment>
    );

}

export default Chat;