import React, { Fragment, useState } from "react";


import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow-blue.png"
import sidenav from "../../assets/images/sidenav.png"
import info from "../../assets/images/info-white.png"
import loan from "../../assets/images/loan-white.png"
import intel from "../../assets/images/intel.png"
import rgtarr from "../../assets/images/right-arrow-blue.png"


import { useNavigate } from "react-router";


const Mining = (props) => {

    const navigate = useNavigate();

    const [isActive, setActive] = useState(false);
    const SideNavClick = () => {
        setActive(!isActive);
    };


    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className={isActive ? "HmPg MinePg sbMenu" : "HmPg MinePg"}>
                        <Sidebar setActive={SideNavClick} />
                        <div className="container">
                          
                            <div className="DfltTp MineTp">
                                <div className="DfltTpIg">
                                    <img src={lftarr} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                    <img src={sidenav} onClick={SideNavClick} />
                                    
                                </div>
                                <h1>Mining Machine Leasing</h1>
                                <p>For easy coin mining...</p>
                                <div className="MineTpIG">
                                    <img src={info} className="mr-2" />
                                    <img src={loan} />
                                </div>
                            </div>
                            <div className="ArbRgePrdDvDts">
                                <div className="ArbRgePrdDvDtsTp">
                                    <button className="btn DaysBtn mr-3">30 Days</button>
                                    <h4>Mining V1</h4>
                                </div>
                                <div className="MineRent">
                                    <div>
                                        <h5>Rent</h5>
                                        <h6>100 USDT</h6>
                                    </div>
                                    <img src={rgtarr} />
                                </div>
                            </div>
                            <div className="ArbRgeIntroVl">
                                <div className="ArbRgeIntroVlDts">
                                    <div className="ArbRgeIntroVlDtsR">
                                        <div>
                                            <a href="#">Cloud mining machine</a>
                                            <h6>Safe and stable money making tool</h6>
                                        </div>
                                        <img src={intel} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

             
            </div>
        </Fragment>
    );

}

export default Mining;