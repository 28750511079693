import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/style.scss';

import { CustomizeProvider } from './Services/Context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { SocketContext, socket } from "../src/Services/Context/socket";


export const queryClient = new QueryClient();





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CustomizeProvider>
      <QueryClientProvider client={queryClient}>
        <SocketContext.Provider value={socket}>
        {(() => {
            if(window.location.pathname !== '/landing'){
                return typeof window.ethereum !== "undefined" ? '' : window.location.href='/landing'
            }
        })()}
          <App />
        </SocketContext.Provider>
      </QueryClientProvider>
    </CustomizeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
