import React, { Fragment, useEffect, useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



import lftarr from "../../assets/images/left-arrow.png"
import loan from "../../assets/images/buy-coin-list.png"
import dwnarr from "../../assets/images/down-arrow.png"

import { useNavigate } from "react-router";
import { NumericFormat } from "react-number-format";
import USER_APIS from "../../Services/APIS";
import { usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import { useCustomizeContext } from "../../Services/Context";
import { toast } from "react-toastify";


const Loan = (props) => {

    const {
        loaderProperty: {
            setLoading
        },

    } = useCustomizeContext();

    const [isAddressValid, setIsAddressValid] = useState(true);
    const navigate = useNavigate();
    const [uniqueid, Setuniqueid] = useState('')
    const [reciveaddress, Setreciveaddress] = useState('')
    const [amount, Setamount] = useState('')
    const [currency, Setcurrency] = useState('')
    const [crypto, setSelectedCrypto] = useState('')

    let { mutateAsync: getWallet } = usePostRequest(QueryKeys?.GET_WALLET_DETAILS_KEY);
    let { mutateAsync: sendloan } = usePostRequest(QueryKeys?.SEND_LOAN_REQUEST);


    useEffect(() => {
        getCurrencyDetails();
    }, []);


    const getCurrencyDetails = async () => {
        let payload = {
            "page": 1,
            "limit": 15
        }

        let postObj = {
            Api: USER_APIS?.GET_WALLET_DETAILS,
            Payload: payload,
            Type: ""
        }
        let res = await getWallet(postObj);
        if (res?.status === true) {
            Setcurrency(res?.data);
        }
    }

    const validateAddress = (address) => {
        address = address.trim();
    
        const hasSpecialChars = /[^a-zA-Z0-9]/.test(address); 
        
        return !hasSpecialChars; 
    };

    const handleAddressChange = (e) => {
        const address = e.target.value;
        Setreciveaddress(address);
   
        setIsAddressValid(validateAddress(address));
    };


    const isAllowed = (values) => {
        const { floatValue } = values;
        const regex = /^\d*\.?\d{0,2}$/; 
    
        return regex.test(values.value);
      };
    

    const sendRequest = async () => {
        
        if(isAddressValid){
               let payload = {
            "reqUid": uniqueid,
            "walletAddress": reciveaddress,
            "amount": amount,
            "currencyId": crypto.cid

        }
        let postObj = {
            Api: USER_APIS?.SEND_LOAN_REQUEST,
            Payload: payload,
            Type: ""
        }
        setLoading(true);
        let res = await sendloan(postObj);
        if (res?.status === true) {
            setLoading(false);
            navigate("/Loanhistory")
        }
        else
            setLoading(false);
        }else{
            toast.error("Invalid Address!")
        }
     
    }


    const handleItemClick = (selectedCrypto) => {
        setSelectedCrypto(selectedCrypto);
    };

 

    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="HmPg SmtLnPg">
                        <div className="container">
                            {/* <div className="DfltCntLd" onClick={() => navigate("/chat")}> </div> */}
                            <div className="DfltTp SmtLnTp">
                                <div className="DfltTpIg mb-4">
                                    <img src={lftarr} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                     <img src={loan} style={{ cursor: "pointer" }} onClick={() => navigate("/loanhistory")} />
                                </div>
                                <h1 className="mb-0">Smart Loans Crypto<br />Platform</h1>
                                <p className="mt-0">Get loans in Minutes!</p>
                            </div>
                            <div className="DeptDfltBg">
                                <div className="DeptFdsDv">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7">
                                            <h3>Loan</h3>
                                            <div className="DeptFdsDvCnt">
                                                <div className="form-group DeptFdsDvCntFrmGrp">
                                                    <label>Account Number</label>
                                                    <NumericFormat
                                                        onChange={(e) => Setuniqueid(e.target.value)}
                                                        placeholder="Enter Unique ID"
                                                        allowNegative={false}
                                                        value={uniqueid}
                                                        className="form-control"
                                                        maxLength={5}
                                                    />
                                                </div>
                                                <div className="form-group DeptFdsDvCntFrmGrp">
                                                <label>Enter receiving address</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleAddressChange}
                                                            value={reciveaddress}
                                                            className={`form-control ${!isAddressValid ? 'is-invalid' : ''}`} 
                                                            placeholder="Enter receiving address"
                                                        />
                                                        {!isAddressValid && (
                                                            <div className="invalid-feedback">
                                                                Please enter a valid Wallet address.
                                                            </div>
                                                        )}
                                                    </div>
                                                <div className="form-group DeptFdsDvCntFrmGrp">
                                                    <label>Select Crypto</label>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={crypto.image} className="mr-2" />
                                                                    <span className="w-auto mb-0">{crypto.symbol ? crypto.symbol : "Select Currency"}</span>

                                                                </div>
                                                                <img src={dwnarr} />
                                                            </div>
                                                        </DropdownToggle>

                                                        <DropdownMenu>
                                                            {currency && currency.map(crypto => (

                                                                <DropdownItem key={crypto.id} onClick={() => handleItemClick(crypto)}>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={crypto.image} className="mr-2" />
                                                                        <span className="w-auto m-0">{crypto.symbol}</span>
                                                                    </div>
                                                                </DropdownItem>

                                                            ))}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                                <div className="form-group DeptFdsDvCntFrmGrp">
                                                    <label>Amount</label>

                                                    <NumericFormat
                                                     isAllowed={isAllowed}
                                                     onValueChange={(values) => Setamount(values.floatValue)} 
                                                        placeholder="0.00"
                                                        allowNegative={false}
                                                        value={amount}
                                                        className="form-control"
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <button className="btn CmnBtn w-100 my-5 CmnBtnMb" onClick={() => sendRequest()}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            
            </div>
        </Fragment>
    );

}

export default Loan;
