import React, { Fragment, useState } from "react";
import { Modal, ModalBody } from 'reactstrap';
import account from "../../assets/images/account.svg"
import arbitrage from "../../assets/images/arbitrage.svg"
import mining from "../../assets/images/mining.svg"
import loan from "../../assets/images/loan.svg"

import chats from "../../assets/images/chats.svg"
import settings from "../../assets/images/settings.svg"
import close from "../../assets/images/side-close.png"
import closeMdl from "../../assets/images/close.png"
import rgtarr from "../../assets/images/right-arrow.png"
import notification from "../../assets/images/notification.png"
import pin from "../../assets/images/pin.png"
import mail from "../../assets/images/mail.png"
import globe from "../../assets/images/globe.png"
import trash from "../../assets/images/trash.png"
import tick from "../../assets/images/tick.png"
import { Link } from "react-router-dom";

import { useCustomizeContext } from "../../Services/Context";

import { useNavigate } from "react-router";
import { queryClient } from "../../index";
import { QueryKeys } from "../../Services/QueryKeys";

import { usePostRequest } from "../../Services/Queries";
import USER_APIS from "../../Services/APIS";

import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";


const Sidebar = (props) => {

    let navigate = useNavigate();


    let { mutateAsync: setPin } = usePostRequest(QueryKeys?.SET_PIN_KEY);


    const [modal, setModal] = useState(false);
    const toggleSettings = () => setModal(!modal);

    const toggleGnrtPin = () => setModal1(!modal1);

    const [modal2, setModal2] = useState(false);
    const toggleChsLang = () => setModal2(!modal2);

    const [pinNumber, setPinNumber] = useState("");

    queryClient.refetchQueries({
        queryKey: [QueryKeys?.GET_USER_DETAILS_KEY, 1],
        type: 'active',
        exact: true,
    })


    const {
        userInfo: {
            userId, userObj
        },
        loaderProperty: {
            setLoading
        },
        pinGenerate: {
            modal1, setModal1
        },
        ReserpinStatus : {
            resetpin , setresetpin
        }
    } = useCustomizeContext();


    const pinGenerate = async () => {
        if (pinNumber) {
            let type = "firstpin"
            if(userObj?.verified && userObj?.xnqijphdm && resetpin ){
                type = "resetpin"
            }
            let payload = {
               
                "pin": pinNumber,
                "type" : type
            }
            let postObj = {
                Api: USER_APIS?.SET_PIN_API,
                Payload: payload,
                Type: ""
            }
            setLoading(true);
            let res = await setPin(postObj);
            if (res?.status === true) {
                if(res?.data?.resetpinstatus){
                    setresetpin(false)
                }
                setPinNumber("")
                toggleGnrtPin();

                setLoading(false);
                navigate("/")
            }
            else
                setLoading(false);
        }
        else
            toast.error("PIN is Required !");
    }


    const AvoidSpace = (event) => {
        var k = event ? event.which : window.event.keyCode;
        if (k == 32) event.preventDefault()
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        localStorage.clear();
        toast.success("Complete Cache Cleared !");
        window.location.reload();
    };

    return (
        <Fragment>
            <div className="hdNavMenu">
                <div className="SdNav">
                    <div className="SdNavTp">
                        <p>Crypto.com</p>
                        <h4>UID : {userId ? userId : localStorage.getItem("uid")}</h4>
                        <img src={close} className="SdCls" onClick={props.setActive} />
                    </div>
                    <div className="SdNavMn">
                        <h5>Functions</h5>
                        <ul>
                            <li className="active">
                                <Link to="/wallet"><img src={account} className="mr-2" />Account</Link>
                            </li>
                            <li>
                                <Link to="/joinarbitrage"><img src={arbitrage} className="mr-2" />Arbitrage</Link>
                            </li>
                            <li>
                                <Link to="/miningdetails"><img src={mining} className="mr-2" />Mining</Link>
                            </li>
                            <li>
                                <Link to="/loan"><img src={loan} className="mr-2" />Loan</Link>
                            </li>
                            <li>
                                <Link to="/chat"><img src={chats} className="mr-2" />Chat</Link>
                            </li>
                            <li onClick={toggleSettings}>
                                <Link to=""><img src={settings} className="mr-2" />Settings</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggleSettings} modalClassName="CmnMdl StgsMdl" className="modal-dialog-centered">
                <ModalBody>
                    <div className="CmnMdlHdr">
                        <h5>Set up</h5>
                        <img src={closeMdl} onClick={toggleSettings} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="CmnMdlBdy">
                        <div className="StgsMdlDts">
                            <div className="StgsMdlDtsCt">
                                <div className="StgsMdlDtsCtL" onClick={() => navigate("/notification")}>
                                    <img src={notification} className="mr-2" />
                                    <span>Notification</span>
                                </div>
                                <img src={rgtarr} />
                            </div>
                            <div className="StgsMdlDtsCt" onClick={() => {userObj?.verified && userObj?.xnqijphdm  ? resetpin ? toggleGnrtPin() : navigate("/emailverify") :  navigate("/emailverify")}}>
                                <div className="StgsMdlDtsCtL">
                                    <img src={pin} className="mr-2" />
                                    <span> {userObj?.verified && userObj?.xnqijphdm  ? "Change PIN" :"Generate PIN"}</span>
                                </div>
                                <img src={rgtarr} />
                            </div>
                            
                            <div className="StgsMdlDtsCt" onClick={() => navigate("/emailverify")}>
                                <div className="StgsMdlDtsCtL">
                                    <img src={mail} className="mr-2" />
                                    <span>Email</span>
                                </div>
                                <img src={rgtarr} />
                            </div>
                            <div className="StgsMdlDtsCt" onClick={toggleChsLang}>
                                <div className="StgsMdlDtsCtL">
                                    <img src={globe} className="mr-2" />
                                    <span>English</span>
                                </div>
                                <img src={rgtarr} />
                            </div>
                            <div className="StgsMdlDtsCt">
                                <div className="StgsMdlDtsCtL" onClick={clearCacheData}>
                                    <img src={trash} className="mr-2" />
                                    <span>Clear Cache</span>
                                </div>
                                <img src={rgtarr} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal1} toggle={toggleGnrtPin} modalClassName="CmnMdl GnrtPinMdl" className="modal-dialog-centered">
                <ModalBody>
                    <div className="CmnMdlHdr">
                        <h5>{userObj?.verified === false ? "Please Verify Email": resetpin ? "Change 4 digit PIN" : " Generate 4 digit PIN "}</h5>
                        <img src={closeMdl} onClick={toggleGnrtPin} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="CmnMdlBdy">
                        <div className="GnrtPinDts GnrtPinDgtVal">
                            {
                                userObj?.verified === false ? null :
                                    <>
                                        <label>Enter PIN</label>
                                        <OtpInput
                                            value={pinNumber}
                                            isInputNum={true}
                                            onChange={setPinNumber}
                                            onKeyDown={(event) => AvoidSpace(event)}
                                            numInputs={4}
                                            separator={<span> </span>}
                                        />

                                    </>
                            }
                            <button className="btn CmnBtn w-100 mt-4" disabled={pinNumber.length === 4 ? false : true} onClick={() => userObj?.verified === false ? navigate("/emailverify") :  pinGenerate()}>
                                {userObj?.verified && userObj?.xnqijphdm && !resetpin  ? "Pin Already Registered" : userObj?.verified === false ? "Please Verify Email" : resetpin ? "Change PIN" :"Generate PIN"}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal2} toggle={toggleChsLang} modalClassName="CmnMdl ChsLangMdl" className="modal-dialog-centered">
                <ModalBody>
                    <div className="CmnMdlHdr">
                        <h5>Choose Language</h5>
                        <img src={closeMdl} onClick={toggleChsLang} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="CmnMdlBdy">
                        <div className="ChsLangDts">
                            <div className="ChsLangLst">
                                <Link to="">English</Link>
                                <img src={tick} />
                            </div>
                            <button className="btn CmnBtn w-100 mt-4">Confirm</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );

}

export default Sidebar;



























