import React, { Fragment, useState, useEffect } from "react";

import InputText from "../../common/UI/TextBox/InputText";

import lftarr from "../../assets/images/left-arrow.png"

import { useNavigate } from "react-router-dom";

import { usePostRequest } from "../../Services/Queries";
import { toast } from "react-toastify";
import USER_APIS from "../../Services/APIS";

import { useCustomizeContext } from "../../Services/Context";

import { NumericFormat } from "react-number-format";

import { queryClient } from "../../index";
import { QueryKeys } from "../../Services/QueryKeys";


const EmailVerify = (props) => {


    let navigate = useNavigate();

    let { mutateAsync: sendEmail } = usePostRequest(QueryKeys?.SEND_EMAIL_KEY);
    let { mutateAsync: verifyEmail } = usePostRequest(QueryKeys?.GET_VERIFY_OTP);


    const {
        pinGenerate: {
            modal1, setModal1
        },
        loaderProperty: {
            setLoading
        },
        userInfo: {
             userObj
        },
        ReserpinStatus : {
            resetpin , setresetpin
        }
    } = useCustomizeContext();

    const [isEmailValid, setEmailIsValid] = useState(false);
    const [email, setEmail] = useState("");
    const [otpNumber, setOtpNumber] = useState("");


    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    useEffect(() => {
        if (localStorage.getItem("isEmail") === "true" && localStorage.getItem("email") !== "" && localStorage.getItem("email") !== null) {
            setEmailIsValid(true);
            setEmail(localStorage.getItem("email"));
        }
    }, []);


    const sendNotification = async () => {
        if (email) {
            let isValid = validateEmail(email);
            let type = "firstpin"
            if(userObj?.verified && userObj?.xnqijphdm ){
                type = "resetpin"
            }
            if (isValid) {
                let payload = {
                    "email": email,
                    "type" : type
                }
                let postObj = {
                    Api: USER_APIS?.EMAIL_VERIFICATION_REQUST,
                    Payload: payload,
                    Type: ""
                }
                setLoading(true);
                let res = await sendEmail(postObj)
                if (res?.status === true) {
                    setTimeout(() => {
                        setEmailIsValid(true);
                        localStorage.setItem("isEmail", true);
                        localStorage.setItem("email", email);
                       // setresetpin(true)
                        setLoading(false);
                    }, 1000);
                }
                else
                    setLoading(false)
            }
            else
                toast.error("Invalid Email Format !");

        }
        else {
            toast.error("Email is required !")
        }
    }


    const verifyOtp = async () => {
        if (otpNumber) {
            let type = "firstpin"
            if(userObj?.verified && userObj?.xnqijphdm ){
                type = "resetpin"
            }
            let payload = {
                "email": email,
                "otp": otpNumber,
                "type": type
            }
            let postObj = {
                Api: USER_APIS?.EMAIL_VERIFICATION_REQUST,
                Payload: payload,
                Type: ""
            }
            setLoading(true);
            let res = await verifyEmail(postObj);
            if (res?.status === true) {
                localStorage.removeItem("email");
                localStorage.removeItem("isEmail");
                queryClient?.refetchQueries([QueryKeys?.GET_USER_DETAILS_KEY]);
                setLoading(false);
                if(userObj?.verified && userObj?.xnqijphdm ){
                if(res?.data?.resetpinstatus){
                    setresetpin(true)
                }
                else{
                    setresetpin(false)
                }
                }
               
                setModal1(true);
                navigate("/");
            }
            else
                setLoading(false);
        }
        else
            toast.error("OTP is required !")
    }



    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="EmailVrfyPg">
                        <div className="container">
                           
                            <div className="EmailVrfyTp">
                                <img src={lftarr} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                <h4>Email</h4>
                                <div></div>
                            </div>
                            <div className="EmailVrfyDts">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="EmailVrfyDtsInr">
                                            <InputText type="email" disabled={isEmailValid} label="Email" value={email} placeholder="Please enter your email" onChange={(e) => setEmail(e.target.value)} />

                                            {
                                                isEmailValid && email ?

                                                    <NumericFormat allowLeadingZeros={true} className="form-control" defaultValue={otpNumber} maxLength="6" onChange={(e) => setOtpNumber(e.target.value)} placeholder="Please enter verify code" label="Verify Code" name="" appendTxt={'Send'} />
                                                    :
                                                    null
                                            }
                                            <button className="btn CmnBtn w-100 my-4" onClick={isEmailValid && email ? verifyOtp : sendNotification}>{isEmailValid && email ? "Verify OTP" : "Send OTP"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </Fragment>
    );
}
export default EmailVerify;