import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
// import Footer from "../../common/Footer/Footer";

import dot from "../../assets/images/green-dot.png";
import reddot from "../../assets/images/red-dot.png";
import lftarr from "../../assets/images/left-arrow.png";
import rgtarr from "../../assets/images/right-arrow.png";
import closeMdl from "../../assets/images/close.png";
import reject from "../../assets/images/reject.gif";
import accept from "../../assets/images/accept.gif";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { useNavigate } from "react-router-dom";

import { usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

import nodata from "../../assets/images/nodata.png";

const LoanHistory = (props) => {
  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  const [modal, setModal] = useState(false);
  const toggleLoanNotifiy = () => setModal(!modal);

  const [loanreject, setloanreject] = useState(false);
  const toggleLoanRejected = () => setloanreject(!loanreject);

  const [loanAccept, setloanAccept] = useState(false);
  const toggleLoanAccepted = () => setloanAccept(!loanAccept);

  const [NotificationMessages, setNotificationMessages] = useState([]);

  const [currentvalue, setcurrentvalue] = useState();
  const navigate = useNavigate();

  const [loanCancelled, setCancelled] = useState(false);
  const toggleLoanCancelled=()=> setCancelled(!loanCancelled)

  let { mutateAsync: sendloanstatus } = usePostRequest(
    QueryKeys?.REJECT_LOAN_REQ
  );

  const Loanstatus = async (id) => {
   

    let payload = {
      loanId: id,
     
    };
    let postObj = {
      Api: USER_APIS?.REJECT_LOAN_REQ,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await sendloanstatus(postObj);

    if (res?.status === true) {
      toggleLoanNotifiy();
      getChatMessages({});

      setLoading(false);
      navigate("/loan");
    } else setLoading(false);
  };

  const Opentoggle = (value) => {
  
    if (value.loanStatus == "Pending") {
      setcurrentvalue(value);
      toggleLoanNotifiy();
    } else if (value.loanStatus == "Approved") {
      setcurrentvalue(value);
      toggleLoanAccepted();
    } else if (value.loanStatus == "Cancelled") {
      setcurrentvalue(value);
      toggleLoanCancelled();
    } else if (value.loanStatus == "Rejected") {
      setcurrentvalue(value);
      toggleLoanRejected();
    }
  };

  let { mutateAsync: getInitateNotify } = usePostRequest(
    QueryKeys?.GET_LOAN_HISTORY
  );

  useEffect(() => {
    getChatMessages({});
  }, []);

  const getChatMessages = async (payload) => {
    let postObj = {
      Api: USER_APIS?.GET_LOAN_HISTORY,
      Payload: {
        page: 1,
        length: 10,
      },
    };
    let res = await getInitateNotify(postObj);
    if (res?.status === true) {
      setNotificationMessages(res?.data);
    }
  };

  const shortenAddress = (address, firstCharsCount = 5, lastCharsCount = 4) => {
    if (address?.length <= firstCharsCount + lastCharsCount + 2) {
      return address;
    }
    return `${address?.slice(0, firstCharsCount + 2)}...${address?.slice(
      -lastCharsCount
    )}`;
  };


  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="EmailVrfyPg NotfifyPg">
            <div className="container">
              <div className="EmailVrfyTp">
                <img
                  style={{ cursor: "pointer" }}
                  src={lftarr}
                  onClick={() => navigate(-1)}
                />
                <h4>Loan History</h4>
                <div></div>
              </div>
              <div className="NotfifyBg">
                <h3>Notice</h3>

                {NotificationMessages?.length > 0 ? (
                  NotificationMessages &&
                  NotificationMessages.map((notification) => (
                    <div
                      className="NotfifyInrDts"
                      onClick={() => Opentoggle(notification)}
                    >
                      <div className="NotfifyInrDtsL">
                        <h4>
                          <img
                             
                             src={
                               notification.loanStatus === "Cancelled"
                                 ? reddot : notification.loanStatus === "Rejected" ? reddot
                                 : dot
                             }
                            className="grndt mr-2"
                          />
                     
<span
                                  className={
                                    notification.loanStatus == "Approved"
                                      ? "lnAct"
                                      : notification.loanStatus == "Rejected"  ?"lnRej" 
                                       : notification.loanStatus == "Cancelled" 
                                      ?"lnRej" 
                                      : ""
                                  }
                                >
                            {notification.loanStatus == "Pending"
                              ? "Loan Request"
                              : notification.loanStatus == "Approved"
                              ? "Loan Accepted"
                              : notification.loanStatus == "Rejected"
                              ? "Loan Rejected"
                              : "Loan Cancelled"}
                          </span>{" "}
                          <span className=" mx-2">
                            {Helper.formatDated(notification.reqTime)}
                          </span>
                        </h4>
                        <h5>UID :{notification.toUserDetails.uid}</h5>
                      </div>
                      <img src={rgtarr} className="ml-2" />
                    </div>
                  ))
                ) : (
                  <div className="NdtFnd">
                    <img src={nodata} />
                    No Data
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>

      <Modal
        isOpen={modal}
        toggle={toggleLoanNotifiy}
        modalClassName="CmnMdl LnRqstMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <h5>Loan Request</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanNotifiy}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy LnRqstBdy">
            <a href="#">
              UID : <span>{currentvalue?.toUserDetails?.uid}</span>
            </a>
            <div className="mt-3">
              <div className="LnRqstInr">
                <h5>Amount</h5>
                <h6>
            <span>
                {currentvalue?.reqAmount && !isNaN(Number(currentvalue.reqAmount)) 
                    ? Helper.loanNumberWithCommas(Number(currentvalue.reqAmount)) 
                    : ""}
            </span>{" "}
            {currentvalue?.reqCurrencyId?.currency_symbol}
        </h6>
              </div>
              <div className="LnRqstInr">
                <h5>Receiver address</h5>

                <Tooltip
                  title={currentvalue?.walletAddress}
                  position="top"
                  trigger="mouseenter"
                >
                  <h6>{shortenAddress(currentvalue?.walletAddress)}</h6>
                </Tooltip>
              </div>
              <div className="LnRqstInr">
                <h5>Created time</h5>
                <h6>{Helper.formatDated(currentvalue?.reqTime)}</h6>
              </div>
            </div>
            <div className="AccRejBtn mt-5">
              <button
                className="btn CmnBtn rejbtn w-100"
                onClick={() => Loanstatus(currentvalue?._id)}
              >
                Cancel Loan Request
              </button>
            
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanreject}
        toggle={toggleLoanRejected}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Rejected</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanRejected}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={reject} className="my-4" />
            <h4>
              UID : <span>{currentvalue?.toUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount : <span>{currentvalue?.reqAmount && !isNaN(currentvalue.reqAmount) ? (Helper.loanNumberWithCommas(
                  +currentvalue.reqAmount
                )) : ""}</span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanAccept}
        toggle={toggleLoanAccepted}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Accepted</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanAccepted}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={accept} className="my-4" />
            <h4>
              UID : <span>{currentvalue?.toUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount : <span>{currentvalue?.reqAmount && !isNaN(currentvalue.reqAmount) ? parseFloat(currentvalue.reqAmount).toFixed(3) : ""}</span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={loanCancelled}
        toggle={toggleLoanCancelled}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Cancelled</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanCancelled}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
          <img src={reject} className="my-4" />
          <h4>
              UID : <span>{currentvalue?.toUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount : <span>{currentvalue?.reqAmount && !isNaN(currentvalue.reqAmount) ? parseFloat(currentvalue.reqAmount).toFixed(3) : ""}</span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default LoanHistory;

