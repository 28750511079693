import React, { Fragment, useState, useMemo, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";

import SelectBox from "../../common/UI/SelectBox/SelectBox";
import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow.png";
import sidenav from "../../assets/images/sidenavbuy.png";

import dwnarr from "../../assets/images/down-arrow.png";
import BuyCoinList from "../../assets/images/buy-coin-list.png";

import volume from "../../assets/images/volume.png";
import trans from "../../assets/images/trans.png";
import clock from "../../assets/images/clock.png";
import closeMdl from "../../assets/images/close.png";
import usdtmini from "../../assets/images/usdt-mini.png";

import { useNavigate } from "react-router";
import { useGetRequest, usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useParams } from "react-router";
import { queryClient } from "../..";

import { useCustomizeContext } from "../../Services/Context";
import { NumericFormat } from "react-number-format";

import Helper from "../../Services/Helper";
import { createChart, LineStyle } from "lightweight-charts";
import Minichart from "../Home/Minichart";

const optionsPrice = [
  { value: "(*50%)", label: "(*50%)" },
  { value: "(*75%)", label: "(*75%)" },
];

const handleSelectChange = (value) => { };

const BuyCoin = (props) => {
  let navigate = useNavigate();

  const chartContainerRef = useRef(null);
  const chartInstanceRef = useRef(null);

  let { mutateAsync: getEntrust } = usePostRequest(QueryKeys?.GET_ENTRUST_KEY);
  let { mutateAsync: addEntrustRequest } = usePostRequest(
    QueryKeys?.ADD_ENTRUST_KEY
  );
  let { mutateAsync: getCoindetails } = usePostRequest(
    QueryKeys?.GET_INTERVEL_COIN_DATA
  );

  let { id } = useParams();

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  const [oneCurrencyDetails, setOneCurrencyDetails] = useState("");

  const [currencyDetails, setCurrencyDetails] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isActive, setActive] = useState(false);
  const SideNavClick = () => {
    setActive(!isActive);
  };

  const [modal, setModal] = useState(false);

  const [modal1, setModal1] = useState(false);
  const toggleBuyCoinDelivery = () => setModal1(!modal1);
  const [activeButton, setActiveButton] = useState("5m");

  const [modal2, setModal2] = useState(false);
  const toggleSellCoinDelivery = () => setModal2(!modal2);

  const [modal3, setModal3] = useState(false);
  const toggleSec = () => setModal3(!modal3);

  const [curId, setCurId] = useState("");

  const [entrustDetails, setEntrustDetails] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [atLeastValue, setAtLeastValue] = useState("");
  const [entrustMaxValue, setEntrustMaxValue] = useState("");

  const [fees, setFees] = useState("");

  const [entrustTimeType, setEntrustTimeType] = useState("up");

  const toggleBuyCoinsList = () => setModal(!modal);

  const [price, setPrice] = useState("");
  const [expectedAmount, setExpectedAmount] = useState("");
  const API_END_POINT = Helper?.Baseurl();

  let requestData = {
    Api: `${USER_APIS?.GET_SINGLE_CURRENCY}/${curId ? curId : id}`,
    Query_Key: QueryKeys?.GET_ONE_CURRENCY_KEY,
  };

  let { data: getOneCurrencyDetails } = useGetRequest(requestData);

  let getCurrencyInfoObj = {
    Api: `${USER_APIS?.GET_CURRENCY_DEAILS}/all`,
    Query_Key: QueryKeys?.GET_CURRENCY_KEY,
  };
  let { data: getCurrncyDetails } = useGetRequest(getCurrencyInfoObj);
  const requestCurrency = async (id) => {
    setCurId(id);
    setLoading(true);
    setTimeout(() => {
      queryClient?.refetchQueries([QueryKeys?.GET_ONE_CURRENCY_KEY]);
      fetchData(id, "5m");
     //navigate(`/entrust/${id}`);
      setLoading(false);
      toggleBuyCoinsList();
    }, 1000);
  };

  //chart

  const [response, setResponse] = useState(null); // State to hold the response data

  const fetchData = async (currencyid, intervel) => {
    setLoading(true);
    setActiveButton(intervel);

    try {
      let payload = {
        coinId: currencyid,
        timeInterval: intervel,
      };

      let postObj = {
        Api: USER_APIS?.GET_INTERVEL_DATA,
        Payload: payload,
        Type: "",
      };

      let res = await getCoindetails(postObj);

      let data = await res.data;

      setResponse(data); // Set the response data in the state
    } catch (error) {
      setLoading(false);
      // Handle any errors here
    }
  };

  // useEffect(() => {
  //   if (response) {
  //     setLoading(true);
  //     if (!chartInstanceRef.current) {
  //       const chart = createChart(chartContainerRef.current, {
  //         width: 800,
  //         height: 400,
  //         rightPriceScale: {
  //           borderVisible: false,
  //           visible: false,

  //           // textColor: 'rgba(255, 255, 255, 0.8)', // Custom text color
  //         },
  //         leftPriceScale: {
  //           borderVisible: false,
  //           visible: false,

  //           //textColor: 'rgba(255, 255, 255, 0.8)', // Custom text color
  //         },
  //         layout: {
  //           backgroundColor: "#FFFFFF",
  //           textColor: "rgba(255, 255, 255, 0.8)",
  //           fontFamily: "Arial",
  //         },

  //         grid: {
  //           horzLines: {
  //             visible: false,
  //           },
  //           vertLines: {
  //             visible: false,
  //           },
  //         },

  //         timeScale: {
  //           visible: true,
  //           timeVisible: true,
  //           secondsVisible: false,
  //           fitContent: true,
  //           tickMarkFormatter: (time, tickMarkType, locale) => {
  //             return new Date(time * 1000).toLocaleDateString(locale, {
  //               day: "numeric",
  //               month: "short",
  //               year: "numeric",
  //             });
  //           },
  //           barSpacing: 5,

  //           borderColor: "rgba(255, 255, 255, 0)",
  //           textColor: "rgba(5, 25, 25, 0)",
  //         },
  //        // crossHair: { mode: 0 },
  //        // handleScroll: false,
  //        // handleScale: false,
  //       });
  //       const container = document.getElementById("container");

  //       const toolTip = document.createElement("span");

  //       const toolTipWidth = 80;

  //       // toolTip.style = ` position: absolute; display: none; backgroundcolor:black; padding: 0px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
  //       toolTip.style = `width: ${toolTipWidth}px;   position: relative; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: center; z-index: 1000; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;

  //       toolTip.style.background = "black";
  //       toolTip.style.color = "white";
  //       toolTip.style.borderColor = "#white";
  //       container.appendChild(toolTip);

  //       // Add area series
  //       const areaSeries = chart.addAreaSeries({
  //         priceLineVisible: false,
  //         priceFormat: {
  //           type: "price",
  //           precision: 4,
  //         },
  //         lastValueVisible: false,
  //         topColor: "#dde3fd",
  //         bottomColor: "#FFFFFF",
  //         lineColor: "rgb(22, 82, 240)",
  //         lineWidth: 2,
  //       });
  //       chart
  //         .timeScale()
  //         .applyOptions({
  //           fixLeftEdge: true,
  //           timeVisible: true,
  //           lockVisibleTimeRangeOnResize: true,
  //         });
  //       chart.timeScale().fitContent();
  //       chart.timeScale().subscribeVisibleLogicalRangeChange((range) => {
  //         console.log(range);
  //       });
  //       // chart.

  //       chart.subscribeCrosshairMove((lineSerie) => {
  //         // console.log("🚀 ~ chart.subscribeCrosshairMove ~ lineSerie:", lineSerie)
  //         const data = Array.from(Array.from(lineSerie.seriesData));
  //         // console.log("🚀 ~ chart.subscribeCrosshairMove ~ data:", data)
  //         if (data.length != 0) {
  //           toolTip.style.display = "block";
  //           let price = data[0][1]?.value;
  //           toolTip.innerHTML = `<span>${price.toFixed(2)}</span>`;

  //           let left = lineSerie.point.x; // relative to timeScale
  //           const timeScaleWidth = chart.timeScale().width();
  //           const priceScaleWidth = chart.priceScale("left").width();
  //           const halfTooltipWidth = toolTipWidth / 2;
  //           left += priceScaleWidth - halfTooltipWidth;
  //           left = Math.min(
  //             left,
  //             priceScaleWidth + timeScaleWidth - toolTipWidth
  //           );
  //           left = Math.max(left, priceScaleWidth);

  //           toolTip.style.left = left + "px";
  //           toolTip.style.bottom = lineSerie.point.y + "px";
  //         }
  //         else {
  //           toolTip.style.display = "none";
  //         }
  //       });

  //       // Add line series
  //       const lineSeries = chart.addLineSeries({
  //         color: "#2962FF",
  //         lineWidth: 2,
  //         lastValueVisible: false,
  //         priceLineVisible: false,
  //       });

  //       // Determine minimum and maximum prices
  //       let minimumPrice = response[0].value;
  //       let maximumPrice = minimumPrice;
  //       for (let i = 1; i < response.length; i++) {
  //         const price = response[i].value;
  //         if (price > maximumPrice) {
  //           maximumPrice = price;
  //           console.log("🚀 ~ useEffect ~ maximumPrice:", maximumPrice)
  //         }
  //         if (price < minimumPrice) {
  //           minimumPrice = price;
  //           console.log("🚀 ~ useEffect ~ minimumPrice:", minimumPrice)
  //         }
  //       }

  //       // Define price lines
  //       const lineWidth = 2;
  //       const minPriceLine = {
  //         price: minimumPrice,
  //         color: "#949599",
  //         lineWidth: lineWidth,
  //         lineStyle: LineStyle.LargeDashed,
  //         axisLabelVisible: true,
  //         title: minimumPrice,
  //       };

  //       const maxPriceLine = {
  //         price: maximumPrice,
  //         color: "#949599",

  //         lineWidth: lineWidth,
  //         lineStyle: LineStyle.LargeDashed,
  //         axisLabelVisible: true,
  //         title: maximumPrice,
  //       };

  //       // Set data and create price lines for area series
  //       if (response && Array.isArray(response) && response.length > 0) {
  //         areaSeries.setData(response);
  //        // areaSeries.createPriceLine(minPriceLine);
  //        // areaSeries.createPriceLine(maxPriceLine);
  //        const minPriceLiner = areaSeries.createPriceLine(minPriceLine);
  //        const maxPriceLiner = areaSeries.createPriceLine(maxPriceLine);
        

  //       // Store chart and series references
  //       chartInstanceRef.current = { chart, areaSeries, lineSeries ,minPriceLiner, maxPriceLiner};
  //       }
  //     } else {
  //       // Update data for existing area series
  //       const { minPriceLiner, maxPriceLiner, areaSeries } = chartInstanceRef.current;
        

  //       if (minPriceLiner) {
        
  //         areaSeries.removePriceLine(minPriceLiner);
  //       }
  //       if (maxPriceLiner) {
          
  //         areaSeries.removePriceLine(maxPriceLiner);
  //       }

  //       let minimumPrice = response[0].value;
  //       let maximumPrice = minimumPrice;
  //       for (let i = 1; i < response.length; i++) {
  //         const price = response[i].value;
  //         if (price > maximumPrice) {
  //           maximumPrice = price;
         
  //         }
  //         if (price < minimumPrice) {
  //           minimumPrice = price;
            
  //         }
  //       }


  //       const minPriceLines= {
  //         price: minimumPrice,
  //         color: "#949599",
          
  //         lineWidth: 2,
  //         lineStyle: LineStyle.LargeDashed,
  //         axisLabelVisible: true,
  //         title: minimumPrice,
  //       };

  //       const maxPriceLines = {
  //         price: maximumPrice,
  //         color: "#949599",

  //         lineWidth: 2,
  //         lineStyle: LineStyle.LargeDashed,
  //         axisLabelVisible: true,
  //         title: maximumPrice,
  //       };

  //     //  chartInstanceRef.current.lineSeries.update(minimumPrice)
  //     chartInstanceRef.current.areaSeries.setData(response);

  //       // chartInstanceRef.current.areaSeries.createPriceLine(minPriceLines);
  //       // chartInstanceRef.current.areaSeries.createPriceLine(maxPriceLines);


  //       const newMinPriceLine = areaSeries.createPriceLine(minPriceLines);
  //       const newMaxPriceLine = areaSeries.createPriceLine(maxPriceLines);

  //       chartInstanceRef.current.minPriceLine = newMinPriceLine;
  //       chartInstanceRef.current.maxPriceLine = newMaxPriceLine;
       

  //     }
  //   // console.log("🚀 ~ useEffect ~ minimumPrice==:", minimumPrice)

  //     setLoading(false);
  //     setIsLoaded(true);
  //   }
  // }, [response, oneCurrencyDetails]);



  useEffect(() => {
    if (response) {
      setLoading(true);
      if (!chartInstanceRef.current) {
        const chart = createChart(chartContainerRef.current, {
          width: 800,
          height: 400,
          rightPriceScale: {
            borderVisible: false,
            visible: false,
          },
          leftPriceScale: {
            borderVisible: false,
            visible: false,
          },
          layout: {
            backgroundColor: "#FFFFFF",
            textColor: "rgba(255, 255, 255, 0.8)",
            fontFamily: "Arial",
          },
          grid: {
            horzLines: {
              visible: false,
            },
            vertLines: {
              visible: false,
            },
          },
          timeScale: {
            visible: true,
            timeVisible: true,
            secondsVisible: false,
            fitContent: true,
            tickMarkFormatter: (time, tickMarkType, locale) => {
              return new Date(time * 1000).toLocaleDateString(locale, {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
            },
            barSpacing: 5,
            borderColor: "rgba(255, 255, 255, 0)",
            textColor: "rgba(5, 25, 25, 0)",
          
          },
          // crossHair: { mode: 0 },
          // handleScroll: false,
          // handleScale: false,
        });
  
        const container = document.getElementById("container");
        const toolTip = document.createElement("span");
        const toolTipWidth = 80;
  
        toolTip.style = `width: ${toolTipWidth}px; position: relative; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: center; z-index: 1000; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTip.style.background = "black";
        toolTip.style.color = "white";
        container.appendChild(toolTip);
  
        // Add area series
        const areaSeries = chart.addAreaSeries({
          priceLineVisible: false,
          priceFormat: {
            type: "price",
            precision: 4,
          },
          lastValueVisible: false,
          topColor: "#dde3fd",
          bottomColor: "#FFFFFF",
          lineColor: "rgb(22, 82, 240)",
          lineWidth: 2,
        });
  
        // Subscribe to crosshair move for tooltip
        chart.subscribeCrosshairMove((lineSerie) => {
          const data = Array.from(lineSerie.seriesData);
          if (data.length !== 0) {
            toolTip.style.display = "block";
            let price = data[0][1]?.value;
            toolTip.innerHTML = `<span>${price.toFixed(2)}</span>`;
            // Positioning tooltip
            let left = lineSerie.point.x;
            const timeScaleWidth = chart.timeScale().width();
            const priceScaleWidth = chart.priceScale("left").width();
            const halfTooltipWidth = toolTipWidth / 2;
            left += priceScaleWidth - halfTooltipWidth;
            left = Math.min(left, priceScaleWidth + timeScaleWidth - toolTipWidth);
            left = Math.max(left, priceScaleWidth);
            toolTip.style.left = left + "px";
            toolTip.style.bottom = lineSerie.point.y + "px";
          } else {
            toolTip.style.display = "none";
          }
        });
  
        // Add line series
        const lineSeries = chart.addLineSeries({
          color: "#2962FF",
          lineWidth: 2,
          lastValueVisible: false,
          priceLineVisible: false,
        });
  
        // Determine minimum and maximum prices
        let minimumPrice = response[0].value;
        let maximumPrice = minimumPrice;
        for (let i = 1; i < response.length; i++) {
          const price = response[i].value;
          if (price > maximumPrice) {
            maximumPrice = price;
          }
          if (price < minimumPrice) {
            minimumPrice = price;
          }
        }
  
        // Define price lines
        const lineWidth = 2;
        const minPriceLineConfig = {
          price: minimumPrice,
          color: "#949599",
          lineWidth: lineWidth,
          lineStyle: LineStyle.LargeDashed,
          axisLabelVisible: true,
          title: minimumPrice,
        };
  
        const maxPriceLineConfig = {
          price: maximumPrice,
          color: "#949599",
          lineWidth: lineWidth,
          lineStyle: LineStyle.LargeDashed,
          axisLabelVisible: true,
          title: maximumPrice,
        };
  
        // Set data and create price lines for area series
        if (response && Array.isArray(response) && response.length > 0) {
          areaSeries.setData(response);
          const minPriceLine = areaSeries.createPriceLine(minPriceLineConfig);
          const maxPriceLine = areaSeries.createPriceLine(maxPriceLineConfig);
          chart.timeScale().fitContent();

  
          // Store chart and series references
          chartInstanceRef.current = { chart, areaSeries, lineSeries, minPriceLine, maxPriceLine };
        }
      } else {
        // Update data for existing area series
        const { areaSeries } = chartInstanceRef.current;
  
        // Remove old minimum and maximum price lines
        if (chartInstanceRef.current.minPriceLine) {
          areaSeries.removePriceLine(chartInstanceRef.current.minPriceLine);
        }
        if (chartInstanceRef.current.maxPriceLine) {
          areaSeries.removePriceLine(chartInstanceRef.current.maxPriceLine);
        }
  
        let minimumPrice = response[0].value;
        let maximumPrice = minimumPrice;
        for (let i = 1; i < response.length; i++) {
          const price = response[i].value;
          if (price > maximumPrice) {
            maximumPrice = price;
          }
          if (price < minimumPrice) {
            minimumPrice = price;
          }
        }
  
        const newMinPriceLineConfig = {
          price: minimumPrice,
          color: "#949599",
          lineWidth: 2,
          lineStyle: LineStyle.LargeDashed,
          axisLabelVisible: true,
          title: minimumPrice,
        };
  
        const newMaxPriceLineConfig = {
          price: maximumPrice,
          color: "#949599",
          lineWidth: 2,
          lineStyle: LineStyle.LargeDashed,
          axisLabelVisible: true,
          title: maximumPrice,
        };
  
        // Update data for area series
        chartInstanceRef.current.areaSeries.setData(response);
        const newMinPriceLine = areaSeries.createPriceLine(newMinPriceLineConfig);
        const newMaxPriceLine = areaSeries.createPriceLine(newMaxPriceLineConfig);
  
        // Update chart references
        chartInstanceRef.current.minPriceLine = newMinPriceLine;
        chartInstanceRef.current.maxPriceLine = newMaxPriceLine;
      }
  
      setLoading(false);
      setIsLoaded(true);
    }
  }, [response, oneCurrencyDetails]);




  

  const getOverAllCurrency = useMemo(() => {
    if (getCurrncyDetails?.status === true) {
      setCurrencyDetails(getCurrncyDetails?.data);
    }
  }, [getCurrncyDetails]);

  const getOneCurrency = useMemo(() => {
    if (getOneCurrencyDetails?.status === true) {
      setOneCurrencyDetails(getOneCurrencyDetails?.data);
    }
  }, [getOneCurrencyDetails]);



  const getEntrustDetails = async () => {
    setLoading(true);
    let payload = {
      id: curId,
    };

    let postObj = {
      Api: USER_APIS?.GET_ENTRUST_DETAILS,
      Payload: payload,
      Type: "",
    };

    let res = await getEntrust(postObj);

    if (res?.status) {
      toggleBuyCoinDelivery();
      setEntrustDetails(res?.data);
      setSelectedTime(res?.data?.entrust[0]?.deliverytime);
      setPriceRange(res?.data?.entrust[0]?.pricepercentage);
      setAtLeastValue(res?.data?.entrust[0]?.atleast);
      setEntrustMaxValue(res?.data?.entrust[0]?.maxdeposit);
      setFees(res?.data?.entrust[0]?.fee);
      setLoading(false);
    } else setLoading(false);
  };

  const setTimeLine = (temp) => {
    setSelectedTime(temp?.deliverytime);
    setPriceRange(temp?.pricepercentage);
    setAtLeastValue(temp?.atleast);
    setEntrustMaxValue(temp?.maxdeposit);
    setFees(temp?.fee);
    toggleSec();
  };

  const addEntrust = async () => {
    let payload = {
      currencyid: curId,
      deliverytime: selectedTime,
      pricerange: priceRange,
      purchaseprice: price,
      expectedamount: expectedAmount,
      predection: entrustTimeType,
      fee: fees,
      userid: localStorage.getItem("uid"),
      cid: "666996af01a86e02a9682c1b",
    };

    let postObj = {
      Api: USER_APIS?.ADD_ENTRUST_REQUEST,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await addEntrustRequest(postObj);
    if (res?.status === true) {
      navigate("/");
      setTimeout(() => {
        setLoading(false);
        navigate(`/mycontract/${oneCurrencyDetails?._id}`);
      }, 1000);
    } else setLoading(false);
  };



  useEffect(() => {
    setCurId(id);
    fetchData(id, "5m");
  }, [id]);

  const handleOpenmodel = () => {
    toggleBuyCoinsList();
  };

  const [isLoaded, setIsLoaded] = useState(true);

  const expectPriceCalc = (value) => {
    if (value > 0) {
      let temp = value;
      setPrice(value);
      let convertPriceRange = +priceRange / 100;
      let convertFees = +fees / 100;
      let purchaseprice = parseInt(value);
      let val = purchaseprice * convertPriceRange - convertFees;
      let expe = +val + +temp;
      let abs = Math.abs(expe);
      let tofixedabs = abs.toFixed(2);
      setExpectedAmount(tofixedabs);
      
    } else {
      setExpectedAmount("");
    }
  };

  const isAllowed = (values) => {
    const { floatValue } = values;
    const regex = /^\d*\.?\d{0,2}$/;

    return regex.test(values.value);
  };

  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className={isActive ? "HmPg  sbMenu" : "HmPg"}>
            <Sidebar setActive={SideNavClick} />
            <div className="container">
              {/* <div className="DfltCntLd" onClick={() => navigate("/chat")}> </div> */}
              <div className="DfltTp ByCnTp">
                <div className="DfltTpIg">
                  <img src={lftarr} onClick={() => navigate("/")} />
                  <img src={sidenav} onClick={SideNavClick} />
                </div>
              </div>
              <div className="ByCnDts">
                <div className="ByCnDtsTp">
                  <div className="ByCnDtsTpL" onClick={handleOpenmodel}>
                    <img src={oneCurrencyDetails?.image} className="mr-2" />
                    <div>
                      <h5>
                        {oneCurrencyDetails?.currency_symbol}
                        <img src={dwnarr} className="ml-2" />
                      </h5>
                      <h6>USDT</h6>
                    </div>
                  </div>
                  <img
                    src={BuyCoinList}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/mycontract/${oneCurrencyDetails?._id}`)
                    }
                  />
                </div>
                <div className="ByCnDtsVol">
                  <h2>
                    ${" "}
                    {oneCurrencyDetails?.price != null
                      ? Helper?.numberFormater(
                        oneCurrencyDetails.price
                          ? (+oneCurrencyDetails.price).toFixed(2)
                          : "0.00"
                      )
                      : "Loading..."}
                  </h2>
                  <h3>
                    {oneCurrencyDetails?.percent_change_24h != null
                      ? Helper?.numberFormater(
                        +oneCurrencyDetails.percent_change_24h
                      )
                      : "Loading..."}
                  </h3>

                  <div
                    className=""
                    id="container"
                    ref={chartContainerRef}
                  >
                    
                  </div>

                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between DrBtmVal">
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "5m" ? "active" : ""
                          }`}
                        onClick={() => fetchData(oneCurrencyDetails?._id, "5m")}
                      >
                        5M
                      </button>
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "15m" ? "active" : ""
                          }`}
                        onClick={() =>
                          fetchData(oneCurrencyDetails?._id, "15m")
                        }
                      >
                        15M
                      </button>
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "1h" ? "active" : ""
                          }`}
                        onClick={() => fetchData(oneCurrencyDetails?._id, "1h")}
                      >
                        1H
                      </button>
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "6h" ? "active" : ""
                          }`}
                        onClick={() => fetchData(oneCurrencyDetails?._id, "6h")}
                      >
                        6H
                      </button>
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "1d" ? "active" : ""
                          }`}
                        onClick={() => fetchData(oneCurrencyDetails?._id, "1d")}
                      >
                        1D
                      </button>
                      <button
                        className={`btn btn-primary flex-fill ${activeButton === "1w" ? "active" : ""
                          }`}
                        onClick={() => fetchData(oneCurrencyDetails?._id, "1w")}
                      >
                        1W
                      </button>
                    </div>
                  </div>

                  <h4>Functions</h4>
                  <div className="ByCnFun">
                    <div className="ByCnFunDts">
                      <span>
                        <img src={volume} className="mr-2" />
                        24h Volume
                      </span>
                      <span>
                        {" "}
                        ${" "}
                        {oneCurrencyDetails?.volume_24h != null
                          ? Helper.formatNumberWithCommas(
                            oneCurrencyDetails.volume_24h
                          )
                          : "Loading..."}
                      </span>
                    </div>
                    <div className="ByCnFunDts mb-5">
                      <span>
                        <img src={trans} className="mr-2" />
                        24h Transaction
                      </span>
                      <span>
                        ${" "}
                        {oneCurrencyDetails?.market_cap != null
                          ? Helper.formatNumberWithCommas(
                            oneCurrencyDetails.market_cap
                          )
                          : "Loading..."}
                      </span>
                    </div>
                  </div>
                  <div className="EntrsBtnDv">
                    <button
                      className="btn CmnBtn EntrstBtn"
                      onClick={() => getEntrustDetails()}
                    >
                      Entrust Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleBuyCoinsList}
        modalClassName="CmnMdl ByCnMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlBdy">
            {currencyDetails?.map((temp, index) =>
              temp?.percent_change_24h && temp?.price ? (
                <div
                  className="ByCnLstCnt"
                  key={index}
                  onClick={() => {
                    requestCurrency(temp?._id);
                  }}
                >
                  <div className="ByCnLstCntL">
                    <img
                      src={temp?.image}
                      className="mr-2"
                      width="28px"
                      height="28px"
                    />
                    <div>
                      <h5>{temp?.currency_symbol}</h5>
                      <h6>USDT</h6>
                    </div>
                  </div>

                  <Minichart
                    chartdata={temp?.miniChartData ? temp?.miniChartData : []}
                    chartcolor={temp?.miniChartColor}
                  />

                  <div className="ByCnLstCntR">
                    <h5 className="MrktTbsTd3H5">
                      $ {Helper?.numberFormater(+temp?.price)}
                    </h5>
                    <h6>
                      <span className={`mr-2 MrktTbsTd3H5 ${Math.sign(
                                          +temp?.percent_change_24h
                                        ) === 1
                                            ? "TxtGrn"
                                            : "Txtdgr"
                                          }`}>
                        {Helper?.numberFormater(+temp?.percent_change_24h)}%
                      </span>

                   
                      24hrs
                    </h6>
                  </div>


                </div>
              ) : null
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal1}
        toggle={toggleBuyCoinDelivery}
        modalClassName="CmnMdl ByCnDelMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div
            className="CmnMdlHdr"
            style={{ paddingBottom: "15px", borderBottom: "1px solid #d9d9d9" }}
          >
            <h5>{oneCurrencyDetails?.currency_name} Delivery</h5>
            <img
              src={closeMdl}
              onClick={toggleBuyCoinDelivery}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy">
            <div className="ByCnDelTp">
              <div className="ByCnDelTpL">
                <img src={oneCurrencyDetails?.image} className="mr-2" />
                <div>
                  <h5>{oneCurrencyDetails?.currency_symbol}</h5>
                  <h6>Buy Up</h6>
                </div>
              </div>
              <div className="ByCnDelTpR">
                <span>
                  <img src={clock} className="mr-2" />
                  {selectedTime === "1m"
                    ? "60S"
                    : selectedTime === "2m"
                      ? "120S"
                      : selectedTime === "1h"
                        ? "60M"
                        : selectedTime === "24h"
                          ? "1D"
                          : "---"}
                </span>
                <h6 className="mt-2">
                  {Helper?.numberFormater(
                    oneCurrencyDetails?.price
                      ? (+oneCurrencyDetails.price).toFixed(2)
                      : "0.00"
                  )}{" "}
                  {oneCurrencyDetails?.currency_symbol}
                </h6>
              </div>
            </div>
            <div className="ByCnDelTm">
              <label>Delivery Time</label>
              <div className="ByCnDelTmSplt">
                <div
                  className="d-flex align-items-center justify-content-between ByCnDelTmSpltMn"
                  onClick={toggleSec}
                >
                  <div className="d-flex align-items-center">
                    <img src={clock} className="mr-2" />
                    <span>
                      {selectedTime === "1m"
                        ? "60S"
                        : selectedTime === "2m"
                          ? "120S"
                          : selectedTime === "1h"
                            ? "60M"
                            : selectedTime === "24h"
                              ? "1D"
                              : "---"}
                    </span>
                  </div>
                  <img src={dwnarr} />
                </div>
                <div className="ByCnDelTmSpltR">
                  <div
                    className="ByCnDelTmSpltGrn"
                    onClick={() => setEntrustTimeType("up")}
                  >
                    Up
                  </div>
                  <div
                    className="ByCnDelTmSpltGray"
                    onClick={() => {
                      setEntrustTimeType("down");
                      toggleSellCoinDelivery();
                      toggleBuyCoinDelivery();
                    }}
                  >
                    Down
                  </div>
                </div>
              </div>
            </div>
            <div className="ByCnDelPrzRng">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <label>Price range</label>
              </div>
              <SelectBox
                optionLabel={`(*${priceRange} %)`}
                disabled={true}
                options={optionsPrice}
                onChange={handleSelectChange}
              ></SelectBox>
            </div>
            <div className="ByCnDelPrzRng">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <label>Purchase Price</label>
                <h6>Fee : {fees} %</h6>
              </div>
              <div className="EmailVrfyDtsInr IPGrp">
                <div className="PurPrz">
                  <img src={usdtmini} className="mr-2" />
                  <span>USDT</span>
                </div>

                <div className="form-group">
                  <div class="input-group">
                    <NumericFormat
                      isAllowed={isAllowed}
                      onValueChange={(values) =>
                        expectPriceCalc(values.floatValue)
                      }
                      placeholder="0.00"
                      allowNegative={false}
                     
                      decimalScale={2}
                      allowLeadingZeros={true}
                      value={price}
                      className="form-control"
                      maxLength={10}
                    />
                    <div
                      class="input-group-append"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const balance =
                          oneCurrencyDetails?.totalUSDTBalance || 0;
                        const calculatedPrice = balance - (+balance * 0.01);
                        expectPriceCalc(calculatedPrice);
                      }}
                    >
                      <span
                        class="input-group-text"
                        style={{ cursor: "pointer" }}
                      >
                        <span className="MxSpn">Max</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ByCnDelAvlBal">
              <div>
                <h6>
                  Available Balance :{" "}
                  {oneCurrencyDetails?.totalUSDTBalance
                    ? Helper?.numberFormater(
                      +oneCurrencyDetails?.totalUSDTBalance
                    )
                    : 0}
                </h6>
                <h6>At least : {atLeastValue}</h6>
              </div>
              {expectedAmount ? (
                <a href="#">Expected :{expectedAmount}</a>
              ) : (
                <a href="#">Expected :{"0.0"}</a>
              )}
            </div>
            <button
              className="btn CmnBtn Entrust-btn w-100"
              onClick={addEntrust}
            >
              Entrust Now
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle={toggleSellCoinDelivery}
        modalClassName="CmnMdl ByCnDelMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div
            className="CmnMdlHdr"
            style={{ paddingBottom: "15px", borderBottom: "1px solid #d9d9d9" }}
          >
            <h5>{oneCurrencyDetails?.currency_name} Delivery</h5>
            <img
              src={closeMdl}
              onClick={toggleSellCoinDelivery}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy">
            <div className="ByCnDelTp">
              <div className="ByCnDelTpL">
                <img src={oneCurrencyDetails?.image} className="mr-2" />
                <div>
                  <h5>{oneCurrencyDetails?.currency_symbol}</h5>
                  <h6>Buy Down</h6>
                </div>
              </div>
              <div className="ByCnDelTpR">
                <span>
                  <img src={clock} className="mr-2" />
                  {selectedTime === "1m"
                    ? "60S"
                    : selectedTime === "2m"
                      ? "120S"
                      : selectedTime === "1h"
                        ? "60M"
                        : selectedTime === "24h"
                          ? "1D"
                          : "---"}
                </span>
                <h6 className="mt-2">
                  {Helper?.numberFormater(+oneCurrencyDetails?.price)}{" "}
                  {oneCurrencyDetails?.currency_symbol}
                </h6>
              </div>
            </div>
            <div className="ByCnDelTm">
              <label>Delivery Time</label>
              <div className="ByCnDelTmSplt">
                <div
                  className="d-flex align-items-center justify-content-between ByCnDelTmSpltMn mb-2"
                  onClick={toggleSec}
                >
                  <div className="d-flex align-items-center">
                    <img src={clock} className="mr-2" />
                    <span>
                      {selectedTime === "1m"
                        ? "60S"
                        : selectedTime === "2m"
                          ? "120S"
                          : selectedTime === "1h"
                            ? "60M"
                            : selectedTime === "24h"
                              ? "1D"
                              : "---"}
                    </span>
                  </div>
                  <img src={dwnarr} />
                </div>
                <div className="ByCnDelTmSpltR mb-2">
                  <div
                    className="ByCnDelTmSpltGrn gray"
                    onClick={() => {
                      setEntrustTimeType("up");
                      toggleBuyCoinDelivery();
                      toggleSellCoinDelivery();
                    }}
                  >
                    Up
                  </div>
                  <div
                    className="ByCnDelTmSpltGray red"
                    onClick={() => setEntrustTimeType("down")}
                  >
                    Down
                  </div>
                </div>
              </div>
            </div>
            <div className="ByCnDelPrzRng">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <label>Price range</label>
              </div>
              <SelectBox
                optionLabel={`(*${priceRange} %)`}
                disabled={true}
                options={optionsPrice}
                onChange={handleSelectChange}
              ></SelectBox>
            </div>
            <div className="ByCnDelPrzRng">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <label>Purchase Price</label>
                <h6>Fee : {fees}%</h6>
              </div>
              <div className="EmailVrfyDtsInr IPGrp">
                <div className="PurPrz">
                  <img src={usdtmini} className="mr-2" />
                  <span>USDT</span>
                </div>

                <div className="form-group">
                  <div class="input-group">
                    <NumericFormat
                      isAllowed={isAllowed}
                      onValueChange={(values) =>
                        expectPriceCalc(values.floatValue)
                      }
                      placeholder="0.00"
                      allowNegative={false}
                      allowLeadingZeros={true}
                      decimalScale={2}
                      value={price}
                      className="form-control"
                      maxLength={10}
                    />
                    <div
                      class="input-group-append"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const balance =
                          oneCurrencyDetails?.totalUSDTBalance || 0;
                        const calculatedPrice = balance - (balance * 0.01);
                        expectPriceCalc(calculatedPrice);
                      }}
                    >
                      <span
                        class="input-group-text"
                        style={{ cursor: "pointer" }}
                      >
                        <span className="MxSpn">Max</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ByCnDelAvlBal">
              <div>
                <h6>
                  Available Balance :{" "}
                  {oneCurrencyDetails?.totalUSDTBalance
                    ? Helper?.numberFormater(
                      +oneCurrencyDetails?.totalUSDTBalance
                    )
                    : 0}
                </h6>
                <h6>At least : {atLeastValue}</h6>
              </div>
              {expectedAmount ? (
                <a href="#">Expected :{expectedAmount}</a>
              ) : (
                <a href="#">Expected :{"0.0"}</a>
              )}
            </div>
            <button
              className="btn CmnBtn Entrust-btn w-100 redbg"
              onClick={addEntrust}
            >
              Entrust Now
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal3}
        toggle={toggleSec}
        modalClassName="CmnMdl TransSecMdl"
        className="modal-dialog-centered"
      >
        <ModalBody className="p-3">
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Entrust coin</h5>
            <img
              src={closeMdl}
              onClick={toggleSec}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy">
            <div className="TransSecDts">
              {entrustDetails?.entrust?.map((temp, index) => (
                <div
                  className="TransSecCnt"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => setTimeLine(temp)}
                >
                  <span>
                    {temp?.deliverytime === "1m"
                      ? "60S"
                      : temp?.deliverytime === "2m"
                        ? "120S"
                        : temp?.deliverytime === "1h"
                          ? "60M"
                          : temp?.deliverytime === "24h"
                            ? "1D"
                            : "---"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default BuyCoin;
