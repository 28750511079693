import React, { Fragment, useEffect, useState } from "react";

// import Footer from "../../common/Footer/Footer";
import lftarr from "../../assets/images/left-arrow.png";
import { useNavigate } from "react-router-dom";
import {  useGetRequest } from "../../Services/Queries";
import { useParams } from "react-router";
import USER_APIS from "../../Services/APIS";
import { QueryKeys } from "../../Services/QueryKeys";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

const MyContractDetails = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();

  const [details, setDetails] = useState();

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  let requestData = {
    Api: `${USER_APIS?.GET_SINGLE_ENTRUST}/${id}`,
    Query_Key: QueryKeys?.GET_SINGLE_ENTRUST,
  };

  let { data: getOneEntrustDetails } = useGetRequest(requestData);

  const singleEntrust = getOneEntrustDetails?.data;



  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="HmPg DeptPg">
            <div className="container">
              <div
                className="DfltTp DeptPgTp"
                style={{ background: "transparent" }}
              >
                <div className="DfltTpIg">
                  <img src={lftarr} onClick={() => navigate(-1)} />
                  <h4 style={{ color: "#353f52" }}>
                    My Contract
                  </h4>
                  <div></div>
                </div>
              </div>
              <div className="mt-3">
                <div className="DeptCnfmDv">
                  <div className="MyCntrctDvDts">
                    <h4>
                      <img
                        src={singleEntrust?.currencyid?.image}
                        className="mr-2"
                      />
                      {singleEntrust?.currencyid?.currency_symbol}/USDT
                      <span className="mx-2">
                        {singleEntrust?.createdAt != null
                          ? Helper?.formatDated(singleEntrust.createdAt)
                          : "Loading..."}
                      </span>
                    </h4>
                    <div className="MyCntrctDvDtsIr">
                      <span>Purchase Amount</span>
                      <span className="Vl">
                        {singleEntrust?.purchaseprice != null
                          ? `${Helper?.numberFormater(+singleEntrust.purchaseprice)} ${"USDT"}`
                          : "Loading..."}
                      </span>
                    </div>
                    <div className="MyCntrctDvDtsIr">
                      <span>Direction</span>
                      <span className="text-suc">
                        {singleEntrust?.predection ? "Up" : "Down"}
                      </span>
                    </div>
                    <div className="MyCntrctDvDtsIr">
                      <span>Purchase price</span>
                      <span className="Vl">
                        {singleEntrust?.marketprice != null
                          ? Helper?.numberFormater(+singleEntrust.marketprice.toFixed(2))
                          : "Loading..."}
                      </span>
                    </div>

                    <div className="MyCntrctDvDtsIr">
                      <span>Contract</span>
                      <span className="Vl">
                        {singleEntrust?.deliverytime === "1m"
                          ? "60S"
                          : singleEntrust?.deliverytime === "2m"
                            ? "120S"
                            : singleEntrust?.deliverytime === "1h"
                              ? "60M"
                              : singleEntrust?.deliverytime === "24h"
                                ? "1D"
                                : "---"}
                      </span>
                    </div>
                    <div className="MyCntrctDvDtsIr">
                      <span>
                      {singleEntrust?.executedstatus ? 
                        singleEntrust?.predectionStatus === false
                          ? "Loss"
                          : "Profit" : "Profit / Loss"}
                      </span>
                      <span>
                      {singleEntrust?.executedstatus ?  
                      <>
                       <span
                        style={{
                          color:
                            singleEntrust?.predectionStatus === false
                              ? "#F54822"
                              : "#57b793",
                        }}

                      >
                        
                     { singleEntrust?.predectionStatus === false ?   parseFloat(singleEntrust?.predectionValue).toFixed(2)  : parseFloat(singleEntrust?.expectedamount).toFixed(2)}
                      </span>
                      </>
                      :
                      <span className="Vl">
                      Waiting .....
                      </span>
                      }
                      </span>

                     
                    </div>
                    <div className="MyCntrctDvDtsIr">
                      <span>Delivery price</span>
                      <span className="Vl">
                        
                        {+singleEntrust?.executedstatus
                          ? Helper?.numberFormater(+singleEntrust.predectionTimePrice.toFixed(2))
                          : "Waiting ....."}
                      </span>
                    </div>



                    <div className="MyCntrctDvDtsIr">
                      <span>Delivery time</span>
                      <span className="Vl">
                        {singleEntrust?.executedstatus != null
                          ? (+singleEntrust.executedstatus
                            ? Helper?.getTwentyFourHoursFormat(singleEntrust?.updatedAt)
                            : Helper?.getTwentyFourHoursFormat(singleEntrust?.createdAt))
                          : "Loading..."}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </Fragment>
  );
};

export default MyContractDetails;












