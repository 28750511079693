import React, { Fragment } from "react";

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import usdt from "../../assets/images/usdt.png"
import tick from "../../assets/images/tick.png"
import ethmini from "../../assets/images/eth-mini.png"
import shield from "../../assets/images/shield.png"
import close from "../../assets/images/arbi-close.png"

import { useNavigate } from "react-router";



const JoinArbitrage = (props) => {

    let navigate = useNavigate();

    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="JnArbRgePg">
                        <div className="container">
                          
                            <div className="JnArbRgeTp">
                                <div className="JnArbRgeTpInr mb-4">
                                    <img src={close} className="JnArbClsIg mb-0" style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                    <a href="#">Arbitrage</a>
                                </div>                               
                                <h2 className="JAIArbH2">Join AI Arbitrage</h2>
                                <h6>Zero risk, fast return</h6>
                            </div>
                            <div className="JnArbRgeDur">
                                <div className="JnArbRgeDurDy">
                                    <h4 className="mr-3"><img src={shield} className="mr-2" />AI Arbitrage</h4>
                                    <button className="btn DaysBtn mr-3">7 Days</button>
                                </div>
                                <div className="JnArbRgeCnTyps">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="JnArbRgeCnTypsCt">
                                                <p>Airbitage coin types</p>
                                                <h3><img src={usdt} className="mr-2" />USDT</h3>
                                                <div className="JnArbRgeCnTypsFlx row">
                                                <div className="JnArbRgeCnTypsVl col-6">
                                                    <h5>Amount</h5>
                                                    <h6>$1000 - 200000</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl col-6">
                                                    <h5>Daily Income</h5>
                                                    <h6>0.6000%</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl col-6">
                                                    <h5>Balance (USDT)</h5>
                                                    <h6>20 USDT</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl col-6">
                                                    <h5>Expected Earnings</h5>
                                                    <h6>88.00 USDT</h6>
                                                </div>
                                                </div>
                                                <div className="form-group DeptFdsDvCntFrmGrp CnvtFrmGrp">
                                                    <label>Hosting Amount</label>
                                                    <div className="IptGrpDv">
                                                        <input type="text" className="form-control" placeholder="0.00" />
                                                        <img src={ethmini} className="IPtGrpIg" />
                                                        <h5 className="IptGrpH5">USDT</h5>
                                                    </div>
                                                </div>
                                                <RangeSlider className="RngSldrDiv" />
                                                <button className="btn CmnBtn w-100">Comming Soon</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="JnArbRgeRls">
                                                <p><img src={tick} className="mr-2" />Daily income is sent to your USDT wallet</p>
                                                <p><img src={tick} className="mr-2" />Zero risk of your investment funds</p>
                                                <p><img src={tick} className="mr-2" />You can get your funds back anytime</p>
                                                <p><img src={tick} className="mr-2" />Artificial intelligence works 24 hours a day</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </Fragment>
    );

}

export default JoinArbitrage;