import React, { Fragment, useEffect, useState } from "react";

import Footer from "../../common/Footer/Footer";
import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow.png";
import sidenav from "../../assets/images/sidenavbuy.png";
import wltbg from "../../assets/images/wallet-bg.svg";

import { usePostRequest } from "../../Services/Queries";

import { useNavigate } from "react-router";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";

import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

const Wallet = (props) => {
  let navigate = useNavigate();

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  const [searchValue, setSearchValue] = useState("");

  const [isActive, setActive] = useState(false);

  const [currencyDeyails, setCurrencyDetails] = useState([]);

  let { mutateAsync: getWallet } = usePostRequest(
    QueryKeys?.GET_WALLET_DETAILS_KEY
  );

  const SideNavClick = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    getCurrencyDetails();
  }, []);

  const getCurrencyDetails = async () => {
    let payload = {
      search: searchValue,
      page: 1,
      limit: 15,
    };

    let postObj = {
      Api: USER_APIS?.GET_WALLET_DETAILS,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await getWallet(postObj);
    if (res?.status === true) {
      setCurrencyDetails(res?.data);
    }
    setLoading(false);
  };

  const navigateWallet = (id) => {
    navigate(`/deposit/${id}`);
  };

  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className={isActive ? "HmPg  sbMenu" : "HmPg"}>
            <Sidebar setActive={SideNavClick} />
            <div className="container">
            
              <div
                className="DfltTp WltTp"
                style={{ background: "transparent" }}
              >
                <div className="DfltTpIg">
                  <img src={lftarr} onClick={() => navigate(-1)} />
                  <img src={sidenav} onClick={SideNavClick} />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
               
                    <h1 className="my-2">Send Crypto Now</h1>
                    <h6>Choose a wallet to send crypto from.</h6>
                  </div>
                  <img src={wltbg} className="WltBg" />
                </div>
              </div>
              <div className="HmMrktTbl">
                <div className="HmMrktTbs">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        {currencyDeyails?.length > 0 ? (
                          currencyDeyails?.map((temp, index) => (
                            <tr
                              key={index}
                              onClick={() => navigateWallet(temp?.cid)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <div className="CnDts">
                                  <img src={temp?.image} className="mr-2" />
                                  <div>
                                    <h5>{temp?.symbol} Wallet</h5>
                                    <h6>{temp?.name}</h6>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                <h5>
                                  {temp
                                    ? !isNaN(+temp.amount)
                                      ? Helper?.numberFormater(
                                          (+temp.amount).toFixed(2)
                                        )
                                      : "0.00"
                                    : "Loading..."}
                                </h5>

                                <h6>
                                  {temp
                                    ? !isNaN(+temp.amount)
                                      ? `${Helper?.numberFormater(
                                          +temp.amount
                                        )} ${temp.symbol}`
                                      : `0 ${temp.symbol}`
                                    : "Loading..."}
                                </h6>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="NoRrdFnd">No Currency Found</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
export default Wallet;
