export const QueryKeys = {
  WALLET_CONNECT_KEY: "connectWallet",
  GET_SITE_DETAILS_KEY: "site_settings_details",
  GET_USER_DETAILS_KEY: "getuserinfo",
  GET_CURRENCY_KEY: "getcurrencyinfo",
  GET_HOME_PAGE_KEY: "gethomepageinfo",
  GET_INITATE_CHAT_KEY: "chatinfo",
  GET_ONE_CURRENCY_KEY: "getonecurrency",
  GET_WALLET_DETAILS_KEY: "getwalletkey",
  SEND_EMAIL_KEY: "sendEmailkey",
  GET_VERIFY_OTP: "emailVerify",
  SET_PIN_KEY: "pingeneration",
  SEND_WITHDRAW_REQUEST: "sendwithdraw",
  GET_ENTRUST_KEY: "entrustkey",
  GET_INITATE_NOTIFY_KEY: "notifyinfo",

  SEND_LOAN_REQUEST: "sendloan",

  ADD_ENTRUST_KEY: "addentrustkey",
  CONVERT_REQUEST_KEY: "sendconvertrequest",
  GET_HISTORY_KEY: "gethistorykey",

  GET_TO_CURRENCY_KEY: "gettocurrencykey",

  SEND_LOAN_STATUS: "sendloanstatus",

  GET_CURRENCY_CHART: "getcurrencychart",
  GET_ENTRUST_HIST_KEY: "getentrusthistory",
  GET_LOAN_HISTORY: "getloanhistory",

  REJECT_LOAN_REQ: "rejectloanreq",

  GET_SINGLE_ENTRUST: "getsingleentrust",
  GET_USER_CHAT: "chatuser",

  GET_INTERVEL_COIN_DATA: "coindata",

  GET_NOTIFY_COUNT: "notifycount",

  GET_MESSAGE: "ugrewdtio",
  NOTIFICATION_STATUS: "uyrteqpu"

}
