import React, { Fragment, useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";


import Sidebar from "../../common/Sidebar/Sidebar";

import { Modal, ModalBody } from "reactstrap";
import closeMdl from "../../assets/images/close.png";
import comment from "../../assets/images/comment.png";

import notify from "../../assets/images/notify.png";
import sidenav from "../../assets/images/sidenav.png";
import { useNavigate } from "react-router-dom";

import { useMemo } from "react";

import { useGetRequest, usePostRequest } from "../../Services/Queries";

import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";

import { QueryKeys } from "../../Services/QueryKeys";
import { queryClient } from "../../index";

import cryptlogo from "../../assets/images/crypto-logo.png";

import Helper from "../../Services/Helper";
import io from "socket.io-client";
import Minichart from "./Minichart";

const Home = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isActive, setActive] = useState(false);
  const SideNavClick = () => {
    setActive(!isActive);
  };

  const [currencyLists, setCurrencyDetails] = useState([]);
  const [homepageContent, setHomepageContent] = useState("");
  const [profile, setProfile] = useState("");
  const [tabType, setTabType] = useState("all");

  const [count, setCount] = useState(0);

  const [modal1, setModal1] = useState(false);
  const toggleChat = () => setModal1(!modal1);

  const {
    loaderProperty: { setLoading },
    userInfo: { setUserId, authToken, setUserObj },
    ReserpinStatus:{setresetpin}
    
  } = useCustomizeContext();

  const navigate = useNavigate();

  let getCurrencyInfoObj = {
    Api: `${USER_APIS?.GET_CURRENCY_DEAILS}/${tabType}`,
    Query_Key: QueryKeys?.GET_CURRENCY_KEY,
  };

  let getUsernotifycount = {
    Api: `${USER_APIS?.GET_NOTIFICATION_COUNT}`,
    Query_Key: QueryKeys?.GET_NOTIFY_COUNT,
  };
  let { data: getCurrncyDetails } = useGetRequest(getCurrencyInfoObj);
  let { data: getUsernotifyCount } = useGetRequest(getUsernotifycount);

  const calculation = useMemo(() => {
    if (getCurrncyDetails?.status === true) {
      setCurrencyDetails(getCurrncyDetails?.data);
    }
  }, [getCurrncyDetails]);

  let getHomepageObj = {
    Api: USER_APIS?.GET_HOME_PAGE_CONTENT,
    Query_Key: QueryKeys?.GET_HOME_PAGE_KEY,
  };

  let { data: getHomepageContent } = useGetRequest(getHomepageObj);

  let { mutateAsync: getUserDetails } = usePostRequest(
    QueryKeys?.GET_USER_DETAILS_KEY
  );

  const getHomepageDetails = useMemo(() => {
    if (getHomepageContent?.status === true) {
      setHomepageContent(getHomepageContent?.data);
    }
  }, [getHomepageContent]);

  const getNotificationDetails = useMemo(() => {
    if (getUsernotifyCount?.status === true) {
      setCount(getUsernotifyCount?.count);
    }
  }, [getUsernotifyCount]);

  const getProfile = async () => {
    if (localStorage.getItem("crypto.com-access-key")) {
      let postObj = {
        Api: USER_APIS?.GET_USER_DETAILS,
        Payload: {},
        Type: "",
      };
      setLoading(true);
      let res = await getUserDetails(postObj);
      if (res?.status === true) {
        localStorage.setItem("uid", res?.data?.uid);
        setUserId(res?.data?.uid);
        setProfile(res?.data);
        setUserObj(res?.data);
        setresetpin(res?.data?.resetpin)
        setLoading(false);
      }
    }
  };

  getUserDetails = useMemo(() => {
    if (authToken) {
      getProfile();
    }
  }, [authToken]);

  const navigateWallet = (id) => {
    navigate(`/entrust/${id}`);
  };

  const tabChange = (tab) => {
    setLoading(true);
    if (tab == "1") {
      toggle("1");
      setTabType("all");
    } else {
      toggle("2");
      setTabType("top");
    }
    setTimeout(() => {
      queryClient?.refetchQueries([QueryKeys?.GET_CURRENCY_KEY]);
      setLoading(false);
    }, 1000);
  };

  let socketURL = Helper?.SocketBaseurl();
  const socket = io.connect(socketURL);

  useEffect(() => {
    socket.on("userNotifyCount", async function (data) {
      if (data?.uid == localStorage.getItem("uid")) {
        setCount(data?.count);
      }
    });
  }, []);

  const userstatus = () => {

    if (localStorage.getItem("crypto.com-access-key") && (localStorage.getItem("userstatus") != "Deactive")){
      return true
    }
    else if(localStorage.getItem("userstatus") == "Deactive"){
      return false
    }

  }

  return (
    <Fragment>
      <div className="MainBg">
        <div className="CntLdDv">
          <div className={isActive ? "HmPg  sbMenu" : "HmPg"}>
            <Sidebar setActive={SideNavClick} />
            <div className="container">
              <div className="DfltTp">
                <div className="DfltTpIg mb-3">
                  <div className="DfltNtfDv">
                    <img
                      src={notify}
                      onClick={() =>
                        localStorage.getItem("crypto.com-access-key")
                          ? navigate("/notification")
                          : ""
                      }
                    />
                    {count !== 0 ? (
                      <span className="DfltNtfCnt1">{`+${count}`}</span>
                    ) : null}
                  </div>
                  <img
                    src={sidenav}
                    onClick={() =>
                      localStorage.getItem("crypto.com-access-key")
                        ? SideNavClick()
                        : ""
                    }
                  />
                </div>
                <div className="d-flex justify-content-center align-items-end">
                  <div>
                    <p>{homepageContent?.title}</p>
                    <h1>{homepageContent?.description}</h1>
                  </div>
                  <img src={cryptlogo} className="cryptlogo ml-3" />
                </div>
              </div>
              <div className="HmMrktTbl mb-3">
                <h2>Market</h2>
                <div className="HmMrktTbs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        All Markets
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                      >
                        Top
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            {currencyLists?.map((temp, index) =>
                              temp?.percent_change_24h && temp?.price ? (
                                <tr
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigateWallet(temp?._id)}
                                >
                                  <td>
                                    <div className="CnDts">
                                      <img
                                        style={{
                                          height: "28px",
                                          width: "28px",
                                        }}
                                        src={temp?.image}
                                        className="mr-2"
                                      />
                                      <div>
                                        <h5>{temp?.currency_symbol}</h5>
                                        <h6>USDT</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <Minichart
                                      chartdata={
                                        temp?.miniChartData
                                          ? temp?.miniChartData
                                          : []
                                      }
                                      chartcolor={temp?.miniChartColor}
                                    />
                                  </td>
                                  <td className="MrktTbsTd3">
                                    <h5 className="MrktTbsTd3H5">
                                      ${" "}
                                      {temp
                                        ? !isNaN(+temp.price)
                                          ? Helper?.numberFormater(
                                            (+temp.price).toFixed(2)
                                          )
                                          : "0.00"
                                        : "Loading..."}
                                    </h5>
                                    <div className="d-flex align-items-center justify-content-end">
                                      <h5
                                        className={`MrktTbsTd3H5 ${Math.sign(
                                          +temp?.percent_change_24h
                                        ) === 1
                                            ? "TxtGrn"
                                            : "Txtdgr"
                                          }`}
                                      >
                                        {temp
                                          ? !isNaN(+temp.percent_change_24h)
                                            ? Helper?.numberFormater(
                                              +temp.percent_change_24h
                                            )
                                            : "0"
                                          : "Loading..."}{" "}
                                        %
                                      </h5>
                                      <h6 className="ml-2 mb-2">24 Hrs</h6>
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            {currencyLists?.map((temp, index) => (
                              <tr
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateWallet(temp?._id)}
                              >
                                <td>
                                  <div className="CnDts">
                                    <img
                                      style={{ height: "28px", width: "28px" }}
                                      src={temp?.image}
                                      className="mr-2"
                                    />
                                    <div>
                                      <h5>{temp?.currency_symbol}</h5>
                                      <h6>USDT</h6>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <Minichart
                                    chartdata={
                                      temp?.miniChartData
                                        ? temp?.miniChartData
                                        : []
                                    }
                                    chartcolor={temp?.miniChartColor}
                                  />
                                </td>
                                <td className="MrktTbsTd3">
                                  <h5 className="MrktTbsTd3H5">
                                    ${" "}
                                    {temp
                                      ? !isNaN(+temp.price)
                                        ? Helper?.numberFormater(
                                          (+temp.price).toFixed(2)
                                        )
                                        : "0.00"
                                      : "Loading..."}
                                  </h5>
                                  <div className="d-flex align-items-center justify-content-end">
                                   
                                      <h5
                                        className={`MrktTbsTd3H5 ${Math.sign(
                                          +temp?.percent_change_24h
                                        ) === 1
                                            ? "TxtGrn"
                                            : "Txtdgr"
                                          }`}
                                      >
                                      {temp
                                        ? !isNaN(+temp.percent_change_24h)
                                          ? Helper?.numberFormater(
                                            +temp.percent_change_24h
                                          )
                                          : "0"
                                        : "Loading..."}{" "}
                                      %
                                    </h5>
                                    <h6 className="ml-2 mb-2">24 Hrs</h6>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <div className="ArbtMineInvt">
                <div className="row">
                  <div className="col-md-6">
                    <div className="ArbtMineInvt ArbtMineInvt1">
                      <h5>{homepageContent?.title_1}</h5>
                      <h4>{homepageContent?.subtitle_1}</h4>
                    </div>
                  </div>
                  <div className="col-md-6 pl-md-0">
                    <div className="ArbtMineInvt ArbtMineInvt2">
                      <h5>{homepageContent?.title_2}</h5>
                      <h4>{homepageContent?.subtitle_2}</h4>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6"></div>
                    </div>
                    <div className="ArbtMineInvt ArbtMineInvt3">
                      <h5>{homepageContent?.title_3}</h5>
                      <h4>{homepageContent?.subtitle_3}</h4>
                      <div style={{ maxWidth: "500px" }}>
                        <p>{homepageContent?.subdescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}

        <Modal
          isOpen={modal1}
          toggle={toggleChat}
          modalClassName="CmnMdl ChatMdl"
          className="modal-dialog-centered"
        >
          <ModalBody>
            <div className="CmnMdlHdr">
              <h5>Chat with us</h5>
              <img
                src={closeMdl}
                onClick={toggleChat}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="CmnMdlBdy chatBdy">
              <img src={comment} />
              <p>Online Service</p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  );
};

export default Home;
