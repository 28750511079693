import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
// import Footer from "../../common/Footer/Footer";

import dot from "../../assets/images/green-dot.png";
import reddot from "../../assets/images/red-dot.png";
import lftarr from "../../assets/images/left-arrow.png";
import rgtarr from "../../assets/images/right-arrow.png";
import closeMdl from "../../assets/images/close.png";
import reject from "../../assets/images/reject.gif";
import accept from "../../assets/images/accept.gif";

import { useNavigate } from "react-router-dom";

import { usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

import nodata from "../../assets/images/nodata.png";

const Notification = (props) => {
  const [activeTab, setActiveTab] = useState("1");


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  const [modal, setModal] = useState(false);
  const toggleLoanNotifiy = () => setModal(!modal);

  const [loanreject, setloanreject] = useState(false);
  const toggleLoanRejected = () => setloanreject(!loanreject);

  const [loanAccept, setloanAccept] = useState(false);
  const toggleLoanAccepted = () => setloanAccept(!loanAccept);

  const [loanCancelled, setCancelled] = useState(false);
  const toggleLoanCancelled=()=> setCancelled(!loanCancelled)

  const [NotificationMessages, setNotificationMessages] = useState([]);
  const [messages, setMessages] = useState([]);

  const [currentvalue, setcurrentvalue] = useState();
  const navigate = useNavigate();

  let { mutateAsync: sendloanstatus } = usePostRequest(
    QueryKeys?.SEND_LOAN_STATUS
  );
  let { mutateAsync: notifactionStatus } = usePostRequest(
    QueryKeys?.NOTIFICATION_STATUS
  );

  const Loanstatus = async (value) => {
    let payload = {
      reqUid: currentvalue?.reqLoanId?._id,
      loanStatus: value,
    };
    let postObj = {
      Api: USER_APIS?.SEND_LOAN_STATUS,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await sendloanstatus(postObj);
    if (res?.status === true) {
      toggleLoanNotifiy();
      getChatMessages({});

      setLoading(false);
      navigate("/notification");
    } else setLoading(false);
  };

  const handleNotifactionStatus = async () => {
    toggle("2");
  
    let postObj = {
      Api: USER_APIS?.NOTIFICATION_STATUS,
      Payload: {},
      Type: "",
    };
    setLoading(true);
    let res = await notifactionStatus(postObj);
    if (res?.status === true) {
    
      setLoading(false);
     
    } else {
      setLoading(false);
    }
  };

  const Opentoggle = (value) => {
    if (value.loanStatus == "Pending") {
      setcurrentvalue(value);
      toggleLoanNotifiy();
    } else if (value.loanStatus == "Approved") {
      setcurrentvalue(value);
      toggleLoanAccepted();
    } else if (value.loanStatus == "Rejected") {
      setcurrentvalue(value);
      toggleLoanRejected();
    } else if (value.loanStatus == "Cancelled") {
      setcurrentvalue(value);
      toggleLoanCancelled();
    }
  };

  let { mutateAsync: getInitateNotify } = usePostRequest(
    QueryKeys?.GET_INITATE_NOTIFY_KEY
  );
  let { mutateAsync: getMessage } = usePostRequest(QueryKeys?.GET_MESSAGE);

  useEffect(() => {
    getChatMessages({});
    handleChatMessage({});
  }, []);

  const getChatMessages = async (payload) => {
    let postObj = {
      Api: USER_APIS?.GET_INITIATE_NOTIFY,
      Payload: {
        page: 1,
        length: 20,
      },
    };
    let res = await getInitateNotify(postObj);
    if (res?.status === true) {
      setNotificationMessages(res?.data);
    }
  };

  const handleChatMessage = async (payload) => {
    let postObj = {
      Api: USER_APIS?.GET_MESSAGE,
      Payload: {
        page: 1,
        length: 20,
      },
    };
    let res = await getMessage(postObj);
    if (res?.status === true) {
      setMessages(res?.data);
    }
  };



  const handleRedirect = (messageType,cid) => {
    switch (messageType) {
        case "deposit":
            navigate(`/transactionhistory/${cid}?tab=1`);
            break;
        case "chat":
            navigate("/chat");
            break;
        case "withdraw":
            navigate(`/transactionhistory/${cid}?tab=2`);
            break;
        case "convert":
            navigate(`/transactionhistory/${cid}?tab=3`);
            break;
        default:
            console.warn("Unhandled message type:", messageType);
            break; 
    }
};

  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="EmailVrfyPg DeptPg NotfifyPg">
            <div className="container">
              {/* <div className="DfltCntLd" onClick={() => navigate("/chat")}> </div> */}
              <div className="EmailVrfyTp">
                <img
                  style={{ cursor: "pointer" }}
                  src={lftarr}
                  onClick={() => navigate("/")}
                />
                <h4>Notification</h4>
                <div></div>
              </div>
              <div className="NotfifyBg">
                <div className="DeptTbs mb-3">
                  <Nav tabs style={{ width: "220px" }}>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Notice
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={
                          () => handleNotifactionStatus()
                          
                        }
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="mt-3">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {NotificationMessages?.length > 0 ? (
                        NotificationMessages &&
                        NotificationMessages.map((notification) => (
                          <div
                            className="NotfifyInrDts"
                            onClick={() => Opentoggle(notification)}
                          >
                            <div className="NotfifyInrDtsL">
                              <h4>
                                <img
                                  src={
                                    notification.loanStatus == "Approved"
                                      ? dot
                                      : notification.loanStatus == "Pending"
                                      ? dot
                                      : reddot
                                  }
                                  className="grndt mr-2"
                                />
                                <span
                                  className={
                                    notification.loanStatus == "Approved"
                                      ? "lnAct"
                                      : notification.loanStatus == "Rejected"  ?"lnRej" 
                                       : notification.loanStatus == "Cancelled" 
                                      ?"lnRej" 
                                      : ""
                                  }
                                >
                                  {notification.loanStatus == "Pending" 
                                    ? "Loan Request"
                                    : notification.loanStatus == "Approved"
                                    ? "Loan Accepted"
                                    : notification.loanStatus == "Cancelled"
                                    ? "Loan Cancelled"
                                    : "Loan Rejected"}
                                </span>{" "}
                                <span className=" mx-2">
                                  {Helper.formatDated(notification.reqTime)}
                                </span>
                              </h4>
                              <h5>UID :{notification.fromUserDetails.uid}</h5>
                            </div>
                            <img src={rgtarr} className="ml-2" />
                          </div>
                        ))
                      ) : (
                        <div className="NdtFnd">
                          <img src={nodata} />
                          No Data
                        </div>
                      )}
                    </TabPane>
                  </TabContent>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="2">
                      {messages?.length > 0 ? (
                        <ul
                          style={{ listStyleType: "disc", paddingLeft: "20px" }}
                        >
                          {messages.map((notification) => (
                            <li
                              key={notification.id}
                              style={{ margin: "5px 0", cursor:'pointer' }}
                              className="NotfifyInrDts"
                              onClick={() => handleRedirect(notification.messageType,notification.currencyId)}
                            >
                              <div className="NotfifyInrDtsL">
                                <h4>
                                  <span>
                                    {notification?.notification || ""}
                                  </span>
                                </h4>
                                <h5>
                                  Time:{" "}
                                  {Helper.formatDated(notification.datetime)}
                                </h5>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="NdtFnd">
                          <img src={nodata} alt="No Data" />
                          No Data
                        </div>
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>

      <Modal
        isOpen={modal}
        toggle={toggleLoanNotifiy}
        modalClassName="CmnMdl LnRqstMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <h5>Loan Request</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanNotifiy}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy LnRqstBdy">
            <a href="#">
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </a>
            <div className="mt-3">
              <div className="LnRqstInr">
                <h5>Amount</h5>
                <h6>
                  <span>
                    {Helper.loanNumberWithCommas(
                      +currentvalue?.reqLoanId?.reqAmount
                    )}
                  </span>{" "}
                  {currentvalue?.reqCurrencyId?.currency_symbol}
                </h6>
              </div>
              <div className="LnRqstInr">
                <h5>Receiver address</h5>
                <h6>{currentvalue?.reqLoanId?.walletAddress}</h6>
              </div>
              <div className="LnRqstInr">
                <h5>Created time</h5>
                <h6>{Helper.formatDated(currentvalue?.reqTime)}</h6>
              </div>
            </div>
            <div className="AccRejBtn mt-5">
              <button
                className="btn CmnBtn rejbtn"
                onClick={() => Loanstatus("reject")}
              >
                Reject
              </button>
              <button
                className="btn CmnBtn actbtn"
                onClick={() => Loanstatus("approve")}
              >
                Accept
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanreject}
        toggle={toggleLoanRejected}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Rejected</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanRejected}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={reject} className="my-4" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanAccept}
        toggle={toggleLoanAccepted}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Accepted</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanAccepted}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={accept} className="my-4" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={loanCancelled}
        toggle={toggleLoanCancelled}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Cancelled</h5>
            <img
              src={closeMdl}
              onClick={toggleLoanCancelled}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="CmnMdlBdy RejBdy">
          <img src={reject} className="my-4" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Notification;
