import React, { createContext, useContext, useState } from "react";

const customizeContext = createContext();

export const useCustomizeContext = () => useContext(customizeContext);
export const CustomizeProvider = ({ children }) => {

    const [siteName, setSiteName] = useState("");
    const [copyrightsContent, setCopyRightsContent] = useState("");
    const [siteLogo, setSiteLogo] = useState("");
    const [siteFavicon, setSiteFavicon] = useState("");

    const [walletAddress, setWalletAddress] = useState("");
    const [authToken, setAuthToken] = useState("");

    const [modal1, setModal1] = useState(false);


    const [userId, setUserId] = useState("");

    const [userObj, setUserObj] = useState({});


    const [loading, setLoading] = useState(false);
    const [withdrawdata , setwithdrawdata] = useState()
    const [resetpin , setresetpin] = useState(false)

    const siteInformation = {
        siteName, setSiteName,
        copyrightsContent, setCopyRightsContent,
        siteLogo, setSiteLogo,
        siteFavicon, setSiteFavicon
    }

    const walletInfo = {
        walletAddress, setWalletAddress,
    }

    const userInfo = {
        userId, setUserId,
        authToken, setAuthToken,
        userObj, setUserObj
    }

    const loaderProperty = {
        loading, setLoading
    }

    const pinGenerate = {
        modal1, setModal1
    }

    const withdrawmessage = {
        withdrawdata , setwithdrawdata

    }
    const ReserpinStatus = {
        resetpin , setresetpin
    }
    return (
        <customizeContext.Provider
            value={{
                siteInformation,
                walletInfo,
                userInfo,
                loaderProperty,
                pinGenerate,
                withdrawmessage,
                ReserpinStatus
            }}
        >
            {children}
        </customizeContext.Provider>
    )
}
