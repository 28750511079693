import React, { Fragment, useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
// import Footer from "../../common/Footer/Footer";
import lftarr from "../../assets/images/left-arrow.png";
import copy from "../../assets/images/copy.png";

import { usePostRequest } from "../../Services/Queries";

import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";
import nodata from "../../assets/images/nodata.png";
import queryString from 'query-string';
import { toast } from "react-toastify";
import { useParams } from "react-router";

const TransactionHistory = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { tab } = queryString.parse(location.search); 
  

  const [activeTab, setActiveTab] = useState(tab || "1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
};


  const [pageSize, setPageSize] = useState(1);
  const [length, setLength] = useState(10);
  const [depositHistory, setDepositHistory] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [convertHistory, setConvertHistory] = useState([]);

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  let { mutateAsync: gethistory } = usePostRequest(QueryKeys?.GET_HISTORY_KEY);
  const {id} = useParams()

  useEffect(() => {
    if(tab){
      if(tab == 2){
        history("withdraw");

      }
      else if(tab == 3){
        history("convert");
       
      }
     
      else{
        history("deposit");
        }

    }
    else{
    history("deposit");
    }
  }, []);

  const history = async (hisType) => {
    let payload = { type: hisType, page: pageSize, length: length ,currencyId:id};

    let postObj = {
      Api: USER_APIS?.GET_HISTORY_REQUEST,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await gethistory(postObj);
    if (res?.status === true) {
      if (hisType === "deposit") setDepositHistory(res?.data);
      else if (hisType === "withdraw") setWithdrawHistory(res?.data);
      else setConvertHistory(res?.data);

      setLoading(false);
    } else setLoading(false);
  };

  const copyFunction = (value) => {
    navigator.clipboard.writeText(value);
    toast.info("Copied !");
  };

  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="HmPg DeptPg">
            <div className="container">
              {/* <div className="DfltCntLd" onClick={() => navigate("/chat")}></div> */}
              <div className="DfltTp DeptPgTp">
                <div className="DfltTpIg mb-4">
                  <img src={lftarr} onClick={() => navigate(-1)} />
                  <h4 style={{ color: "#353f52" }}>Transaction Records</h4>
                  <div></div>
                </div>
                <div className="DeptTbs mb-3">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          history("deposit");
                          toggle("1");
                        }}
                      >
                        Receive
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                          history("withdraw");
                        }}
                      >
                        Send
                      </NavLink>
                    </NavItem>
                    
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                          history("convert");
                        }}
                      >
                        Convert
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="mt-3">
                <TabContent activeTab={activeTab}>
                  {/* Deposit History */}

                  <TabPane tabId="1">
                    {depositHistory?.length > 0 ? (
                      depositHistory?.map((temp, index) => (
                        <div className="DeptCnfmDv" key={index}>
                          <div className="DeptCnfmDvDts">
                            <span className="Tit">
                              Receive {temp?.coinDetails?.currency_symbol}
                            </span>
                            <span
                              className={`Tit ${
                                temp?.adminTxnStatus ? "text-suc" : null
                              }`}
                            >
                              {temp?.adminTxnStatus
                                ? "Successful"
                                : "Confirming..."}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Amount</span>
                            <span className="Vl">
                              {temp?.amount && !isNaN(temp.amount)
                                ? parseFloat(temp.amount).toFixed(2)
                                : ""}{" "}
                              {temp?.coinDetails?.currency_symbol}
                            </span>
                          </div>

                          <div className="DeptCnfmDvDts">
                            <span>Address</span>
                            <span className="Vl">
                              {`${temp?.walletAddress?.slice(
                                0,
                                4
                              )}...${temp?.walletAddress?.slice(-4)}`}{" "}
                              <img
                                src={copy}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  copyFunction(temp?.walletAddress)
                                }
                                className="ml-2 CPyIcn"
                              />
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Chain Name</span>
                            <span className="Vl">
                              {temp?.coinDetails?.currency_symbol}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Created Time</span>
                            <span className="Vl">
                              {Helper?.formatDated(temp?.txnTime)}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="NdtFnd">
                        <img src={nodata} />
                        No Data
                      </div>
                    )}
                  </TabPane>

                  {/* Withdraw History */}

                  <TabPane tabId="2">
                    {withdrawHistory?.length > 0 ? (
                      withdrawHistory?.map((temp, index) => (
                        <div className="DeptCnfmDv" key={index}>
                          <div className="DeptCnfmDvDts">
                            <span className="Tit">
                              Send {temp?.coinDetails?.currency_symbol}
                            </span>
                            <span
                              className={`Tit ${
                                temp?.txnSendStatus === "Approved"
                                  ? "text-suc" : temp?.txnSendStatus === "Rejected" ?
                                  "text-dgr"
                                  : null
                              }`}
                            >
                              {temp?.txnSendStatus === "Approved" 
                                ? "Successful" : temp?.txnSendStatus === "Rejected"  ? "Rejected" 
                                : "Confirming..."}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Amount</span>
                            <span className="Vl">
                              {temp?.amount && !isNaN(temp.amount)
                                ? parseFloat(temp.amount).toFixed(2)
                                : ""}{" "}
                              {temp?.coinDetails?.currency_symbol}
                            </span>
                          </div>

                          <div className="DeptCnfmDvDts">
                            <span>Receive Address</span>
                            <span className="Vl">
                              {`${temp?.walletAddress?.slice(
                                0,
                                4
                              )}...${temp?.walletAddress?.slice(-4)}`}
                              <img
                                src={copy}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  copyFunction(temp?.walletAddress)
                                }
                                className="ml-2"
                              />
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Chain Name</span>
                            <span className="Vl">
                              {temp?.coinDetails?.currency_symbol}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Created Time</span>
                            <span className="Vl">
                              {temp?.txnTime
                                ? Helper.formatDated(temp.txnTime)
                                : "Not Available"}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Confirm Time</span>
                            <span className="Vl">
                              {temp?.txnUpdatedTime
                                ? Helper.formatDated(temp.txnUpdatedTime)
                                : "Not Available"}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="NdtFnd">
                        <img src={nodata} />
                        No Data
                      </div>
                    )}
                  </TabPane>

                  {/* Convert History */}

                  <TabPane tabId="3">
                    {convertHistory?.length > 0 ? (
                      convertHistory?.map((temp, index) => (
                        <div className="DeptCnfmDv" key={index}>
                          <div className="DeptCnfmDvDts">
                            <span className="Tit">
                              Convert {temp?.fromCurrencyId?.currency_symbol}
                            </span>
                            <span
                              className={`Tit ${
                                temp?.adminTxnStatus === "Pending"
                                  ? null
                                  : "text-suc"
                              }`}
                            >
                              {temp?.adminTxnStatus === "Pending"
                                ? "Pending"
                                : "Successful"}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Amount</span>
                            <span className="Vl">
                              {temp?.fromAmount && !isNaN(temp.fromAmount)
                                ? parseFloat(temp.fromAmount).toFixed(2)
                                : "0"}  {" "} 
                              {temp?.fromCurrencyId?.currency_symbol}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Fee</span>
                            <span className="Vl">
                              {temp?.convertFee != null
                                ? `${temp.convertFee} ${temp.fromCurrencyId?.currency_symbol}`
                                : "0"}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>From</span>
                            <span className="Vl">
                              {temp?.fromAmount && !isNaN(temp.fromAmount)
                                ? parseFloat(temp.fromAmount).toFixed(2)
                                : "0"}{" "} 
                              {temp?.fromCurrencyId?.currency_symbol}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>To</span>
                            <span className="Vl">
                              {" "}
                              {temp?.toAmount && !isNaN(temp.toAmount)
                                ? parseFloat(temp.toAmount).toFixed(2)
                                : "0"}{" "} 
                              {temp?.toCurrencyId?.currency_symbol}
                            </span>
                          </div>
                          <div className="DeptCnfmDvDts">
                            <span>Created Time</span>
                            <span className="Vl">
                              {Helper?.formatDated(temp?.userTxnTime)}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="NdtFnd">
                        <img src={nodata} />
                        No Data
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </Fragment>
  );
};

export default TransactionHistory;
