import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectRoute = () => {
    const key = localStorage.getItem('crypto.com-access-key');
    const accountstatus = localStorage.getItem('userstatus')
   
     if (key && accountstatus != "Deactive") {
        return <Outlet />;
    } else {
      
        return <Navigate to="/" />;
    }
       
};

export default ProtectRoute;