const USER_APIS = {
    LOGIN: "login",
    GET_SITE_SETTINGS: "getsitesettings",
    WALLET_CONNECTION: "jhbouilmawq",
    GET_USER_DETAILS: "getuserdetails",
    GET_CURRENCY_DEAILS: "getcurrency",
    GET_HOME_PAGE_CONTENT: "gethomepage",
    GET_INITIATE_CHAT: "puiobyvede",
    CHAT_ATTACHEMENT: "bxgcvemuzq",
    GET_SINGLE_CURRENCY: "currency",
    GET_WALLET_DETAILS: "gudpvhdzgikpxp",
    SET_PIN_API: "chkhbyuebgijym",
    EMAIL_VERIFICATION_REQUST: "ycckhykgjjcyb",
    SEND_WITHDRAW_REQUEST: "wjquaufwrehfa",
    GET_ENTRUST_DETAILS: "tegtsurtne",
    GET_INITIATE_NOTIFY: "porgjdgjkdedd",
    SEND_LOAN_REQUEST: "tseuqernaol",


    ADD_ENTRUST_REQUEST: "ddatsurtne",
    SEND_CONVERT_REQUEST: "azxnuqkyzfz",
    GET_HISTORY_REQUEST: "yepxirmjwj",
    SEND_LOAN_STATUS: "jckdfuvntdxd",

    GET_CURRENCY_CHART: "gkeuiaygdvijrd",
    GET_ENTRUEST_HISTORY: "lkfgalpgrkjg",
    GET_LOAN_HISTORY: "nqvutcdpprwl",
    REJECT_LOAN_REQ: "qjhgtwjpziub",


    GET_SINGLE_ENTRUST: "advtqmiaxm",
    GET_USER_CHAT_DETAILS: "fwyhvkikuhgx",
    GET_INTERVEL_DATA: "atadlavretniemit",
    GET_NOTIFICATION_COUNT: "gytsrtgsan",
    GET_MESSAGE: "ugrewdtio",
    NOTIFICATION_STATUS: "uyrteqpu"



}

export default USER_APIS;
