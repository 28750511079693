import React, { Fragment, useState } from "react";
import lftarr from "../../assets/images/left-arrow.png";
import { useNavigate } from "react-router";
import Sidebar from "../../common/Sidebar/Sidebar";
import sidenav from "../../assets/images/sidenavbuy.png"




const ArbitrageDetails = (props) => {

    let navigate = useNavigate();
    const [isActive, setActive] = useState(false);
    const SideNavClick = () => {
        setActive(!isActive);
    };
    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                     <div className={isActive ? "HmPg MinePg sbMenu" : "HmPg MinePg"}>
                     <Sidebar setActive={SideNavClick} />
                    <div className="JnArbRgePg ArbRgeDtsPg">
                        <div className="container">
                            {/* <div className="DfltCntLd" onClick={() => navigate("/chat")}> </div> */}
                            <div className="JnArbRgeTp ArbRgeDtsPgTp">
                                <div className="d-flex justify-content-between align-items-center">
                                    <img src={lftarr} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                    
                                    <h2 style={{ color: '#353f52' }}>Mining Introduction</h2>
                                    <img src={sidenav} onClick={SideNavClick} />
                                    
                                </div>
                             
                            </div>
                            <div className="JnArbRgeDur ArbRgeDtsPgRls">
                                <h4>What is cloud mining?</h4>
                                <p>Cloud Mining is the process of bitcoin mining utilizing a remote datacenter with shared processing power. This type of cloud mining enables users to mine bitcoins or alternative cryptocurrencies without managing the hardware. The mining rigs are housed and maintained in a facility owned by mining company and the customer simply needs to register and purchase mining contracts.</p>
                                <p>Our app never uses a device's CPU or GPU for cryptocurrency mining operations and not run unrelated background processes. Our APP will not cause potential damage to the hardware, such as battery drain and excessive heat.</p>
                                <p>Our data centers are located in Kazakhstan and Iran, and cheap electricity is the main factor in the construction of our data centers. With the increase in user demand, we also continue to expand data centers around the world.</p>
                                <h4>How to make a withdrawal?</h4>
                                <p>From the moment you rent a mining machine, your mining machine will start generating income for you</p>
                                <p>The coins you mine within 24 hours will be automatically sent to your current account. You can choose the corresponding account to send your coins to the exchange for sale or to your own wallet address</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
             
            </div>
        </Fragment>
    );

}

export default ArbitrageDetails;