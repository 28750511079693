import { useQuery, useMutation } from '@tanstack/react-query'
import Helper from '../Helper'

const API_END_POINT = Helper?.Baseurl();

export const getRequest = async (REQUEST_API) => {
    return await Helper?.GetData(`${API_END_POINT}/${REQUEST_API}`);
}

export const useGetRequest = ({ Api, Query_Key }) => {
    return useQuery({
        queryKey: [Query_Key],
        queryFn: () => getRequest(Api)
    });
}


export const postRequest = async ({ Api, Payload, Type }) => {
    return await Helper?.PostData(`${API_END_POINT}/${Api}`, Payload, Type);
}

export const usePostRequest = (Mutation_Key) => {
    return useMutation({
        mutationFn: postRequest,
        mutationKey: [Mutation_Key]
    })
}