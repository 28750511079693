import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import Footer from "../../common/Footer/Footer";

import lftarr from "../../assets/images/left-arrow.png"
import btc from "../../assets/images/btc.png"
import usdt from "../../assets/images/usdt.png"


const HostingHistory = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="HmPg DeptPg">
                        <div className="container">
                          
                            <div className="DfltTp DeptPgTp" style={{ background: '#C7CAD2' }}>
                                <div className="DfltTpIg mb-4">
                                    <img src={lftarr} />
                                    <h4 style={{ color: '#353f52' }}>Hosting Details</h4>
                                    <div></div>
                                </div>
                                <div className="DeptTbs HstngTbs mb-3">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggle('1'); }}
                                            >
                                                Hosting
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggle('2'); }}
                                            >
                                                Termination
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { toggle('3'); }}
                                            >
                                                Redeemed
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                            <div className="mt-3">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">BITX V1</span>
                                                <span className="text-suc">Completed</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Airbitrage coin type</span>
                                                <span className="Vl">USDT <img src={usdt} className="ml-2" /></span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">100 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Daily Income</span>
                                                <span className="Vl">0.1200 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Earnings</span>
                                                <span className="Vl">40.1254000 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">BITX V3</span>
                                                <span className="text-suc">Completed</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Airbitrage coin type</span>
                                                <span className="Vl">BTC <img src={btc} className="ml-2" /></span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">100 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Daily Income</span>
                                                <span className="Vl">0.1200 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Earnings</span>
                                                <span className="Vl">40.1254000 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">BITX V1</span>
                                                <span className="text-dgr">Terminated</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Airbitrage coin type</span>
                                                <span className="Vl">USDT <img src={usdt} className="ml-2" /></span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">100 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Daily Income</span>
                                                <span className="Vl">0.1200 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Earnings</span>
                                                <span className="Vl">40.1254000 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">BITX V3</span>
                                                <span className="text-dgr">Terminated</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Airbitrage coin type</span>
                                                <span className="Vl">BTC <img src={btc} className="ml-2" /></span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">100 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Daily Income</span>
                                                <span className="Vl">0.1200 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Earnings</span>
                                                <span className="Vl">40.1254000 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">Convert USDT</span>
                                                <span className="Tit text-suc">Successful</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">10.0021548 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Fee</span>
                                                <span className="Vl">0.00 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>From</span>
                                                <span className="Vl">USDT</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>To</span>
                                                <span className="Vl">BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                        <div className="DeptCnfmDv">
                                            <div className="DeptCnfmDvDts">
                                                <span className="Tit">Convert BTC</span>
                                                <span className="Tit text-suc">Successful</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Amount</span>
                                                <span className="Vl">10.0021548 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Fee</span>
                                                <span className="Vl">0.00 BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>From</span>
                                                <span className="Vl">USDT</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>To</span>
                                                <span className="Vl">BTC</span>
                                            </div>
                                            <div className="DeptCnfmDvDts">
                                                <span>Created Time</span>
                                                <span className="Vl">2024/05/20 13:05:45</span>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    );

}

export default HostingHistory;