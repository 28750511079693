import React, { Fragment } from "react";
import { useCustomizeContext } from "../../Services/Context";


const Footer = (props) => {
    React.useEffect(() => { window.scrollTo(0, 0); }, []);


    const {
        siteInformation: {
            copyrightsContent,
        },
    } = useCustomizeContext();

    return (
        <Fragment>
            <div className="FtrSec">
                <div className="container">
                    <p>{copyrightsContent}</p>
                </div>
            </div>
        </Fragment>
    );

}

export default Footer;