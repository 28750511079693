import React, { Fragment } from "react";

// import Footer from "../../common/Footer/Footer";

import lftarr from "../../assets/images/left-arrow.png"
import copy from "../../assets/images/copy.png"
import refer from "../../assets/images/refer.png"
import loan from "../../assets/images/buy-coin-list.png"
import { toast } from "react-toastify";

const Referral = (props) => {
    const copyFunction = () => {
        // navigator.clipboard.writeText(currencyDetails?.mxyurnfbve);
    
        const addressToCopy = '0x3sd5v4sd74gvbs687bg3sad4b6...ae54g'; 
        navigator.clipboard.writeText(addressToCopy); 
        toast.info("Address is Copied !"); 
    }
    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="EmailVrfyPg RefrlPg">
                        <div className="container">
                            {/* <div className="DfltCntLd"> </div> */}
                            <div className="EmailVrfyTp RefrlTp">
                                <img src={lftarr} />
                                <h4>Invite Friends</h4>
                                <img src={loan} />
                            </div>
                            <div className="RefrlBg">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="RefrlIncVal">
                                            <h5>Total Income :</h5>
                                            <h5 className="Vl">0.00 USDT</h5>
                                        </div>
                                        <div className="RefrlIncVal">
                                            <h5>Direct referral commission :</h5>
                                            <h5 className="Vl">0.00 USDT</h5>
                                        </div>
                                        <div className="RefrlIncVal">
                                            <h5>Indirect referral commission :</h5>
                                            <h5 className="Vl">0.00 USDT</h5>
                                        </div>
                                        <img src={refer} className="RefCmsnDvIg" />
                                        <h4>Get Referral Rewards</h4>
                                        <p> When your friends participate in AI arbitrage and rent mining machines,<br />you can get referral rewards</p>
                                        <div className="DeptCpyDv w-100">
                                            <div className="row">
                                             
                                                 <div className="DeptCpyDv">
                                                            <div className="copyCls" onClick={copyFunction}>{'0x3sd5v4sd74gvbs687bg3sad4b6...ae54g'}</div>
                                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>
        </Fragment>
    );

}

export default Referral;