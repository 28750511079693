import React, { Fragment } from "react";


import coinbase from "../../assets/images/coinbase.png"

import appstore from "../../assets/images/apple-store.png"
import playstore from "../../assets/images/google-play.png"
import lndTk from "../../assets/images/land-tick.png"
import lndPhn from "../../assets/images/land-phone.png"


const Landing = (props) => {   

    if(localStorage.getItem("uid")){
        window.location.href=("/")
    }
  
        const openAppStore = () => {
            window.open("https://www.apple.com/in/app-store/", "_blank");
        };
    
        const openPlayStore = () => {
            window.open("https://play.google.com", "_blank");
        };

    return (
        <Fragment>
            <div className="LndPg">
                <div className="LndPgInr">
                    <div className="LndInrImgTp">
                        <h2><img src={coinbase} className="LndInrImgTpIg"/>Coinbase wallet</h2>
                        <h1>Get Coinbase Wallet</h1>                        
                    </div>
                    <h3>The easiest and most secure crypto wallet</h3>
                        <div className="LndPgIntrLst">
                            <ul>
                                <li><img src={lndTk} /><span>Store all your crypto and NFTs in one place</span></li>
                                <li><img src={lndTk} /><span>Trade 500+ assets on DEXes and earn interest</span></li>
                                <li><img src={lndTk} /><span>No Coinbase account required</span></li>
                            </ul>
                        </div>
                        <div className="LndPgIntrIg">
                            <img src={lndPhn} />
                        </div>
                        <div className="LndPgPlyStr">
                        <img src={appstore} alt="App Store" onClick={openAppStore} style={{ cursor: 'pointer' }} />
                        <img src={playstore} alt="Play Store" onClick={openPlayStore} style={{ cursor: 'pointer' }} />
                        </div>             
                </div>
            </div>
        </Fragment>
    );

}

export default Landing;